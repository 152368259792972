import React from 'react';
import Link from 'next/link';
import css from './footer.module.css';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className={css.container}>
        <Link href="/about" prefetch={false}>
          <a className={css.footerLink}>About</a>
        </Link>
        <span className={css.footerLink}> | </span>
        <a href="/blog" className={css.footerLink} target="_blank">
          Blog
        </a>
        <span className={css.footerLink}> | </span>
        <Link href="/terms" prefetch={false}>
          <a className={css.footerLink}>Terms </a>
        </Link>
        <span className={css.footerLink}> | </span>
        <Link href="/privacy" prefetch={false}>
          <a className={css.footerLink}>Privacy</a>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
