import React from 'react';
import css from './selected-svg.module.css';

const SelectedSVG = (props: any): JSX.Element => (
  <svg
    className={css.selectedSVG}
    width={32}
    height={83}
    fill="none"
    {...props}
  >
    <g filter="url(#filter0_ddd)" clipPath="url(#clip0)">
      <path
        d="M-.133 14.812a17.293 17.293 0 006.412 13.44l12.218 9.892a4.343 4.343 0 010 6.75L6.279 54.785A17.294 17.294 0 00-.133 68.227c0 7.33-5.943 13.273-13.274 13.273h-1.927V1.538h1.927c7.33 0 13.274 5.943 13.274 13.274z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          transform="matrix(1 0 0 -1 0 82.166)"
          d="M0 0h32v81.333H0z"
        />
      </clipPath>
      <filter
        id="filter0_ddd"
        x={-31.334}
        y={-10.462}
        width={67.441}
        height={111.962}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={8} />
        <feColorMatrix values="0 0 0 0 0.392157 0 0 0 0 0.313726 0 0 0 0 0.588235 0 0 0 0.08 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={4} />
        <feColorMatrix values="0 0 0 0 0.392157 0 0 0 0 0.313726 0 0 0 0 0.588235 0 0 0 0.08 0" />
        <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feColorMatrix values="0 0 0 0 0.432522 0 0 0 0 0.337812 0 0 0 0 0.5875 0 0 0 0.08 0" />
        <feBlend in2="effect2_dropShadow" result="effect3_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default SelectedSVG;
