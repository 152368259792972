import CitationInputFillManuallyButton from 'components/app/citation-input-fill-manually-button';
import React from 'react';
import ErrorIcon from 'static/svgs/icon/error-icon.svg';
import { CitationInputType } from 'types/citation-input-type';
import { errorCodes, UrlReqError } from 'types/url-req-error';
import { urlReqValidator } from 'lib/url-req-error-helpers';
import Button from './button';
import yn from 'yn';
import Tracking from 'lib/tracking';
import { ChromeSVG } from './header';
import css from './search-error.module.css';

export const chromeExtensionUrl =
  'https://chrome.google.com/webstore/detail/bibcitation-%E2%80%94-automatic-c/cnjkoanefhlhkagbpofjbafaonfejpgc?hl=en';

interface SearchErrorProps {
  errorText: string | JSX.Element;
  inputType?: CitationInputType;
  value?: string;
  showFillManually?: boolean;
  hideInvalidUrl?: boolean;
}

const SearchError = ({
  errorText,
  inputType,
  showFillManually,
  value,
  hideInvalidUrl,
}: SearchErrorProps): JSX.Element => {
  const isChrome =
    navigator.userAgent.includes('Chrome') &&
    navigator.vendor.includes('Google Inc');

  const showPDFDetails = errorText === errorCodes[UrlReqError.PdfParsingError];
  const isInvalidURL = errorText === urlReqValidator('a');
  const showChromeApp =
    !yn(process.env.GDOCS) &&
    (errorText === errorCodes[UrlReqError.Timeout] ||
      errorText === errorCodes[UrlReqError.Forbidden] ||
      errorText === errorCodes[UrlReqError.Malformed]) &&
    isChrome;
  const showInvalidURL =
    typeof hideInvalidUrl !== 'undefined'
      ? !hideInvalidUrl
      : inputType === 'url';

  const handleButtonClick = (): void => {
    Tracking.trackEvent('Clicked on add chrome extension', {
      type: 'button',
    });
  };

  const handleHyperLinkClick = (): void => {
    Tracking.trackEvent('Clicked on add chrome extension', {
      type: 'link',
    });
  };

  return (
    <div>
      <div className={css.searchError}>
        <div className={css.errorMsgContainer}>
          <ErrorIcon />
          <span className={css.errorMsgText}>{errorText}</span>
        </div>

        {showPDFDetails && (
          <div className={css.pdfDetails}>
            <strong>Why did this happen? </strong>We were unable to locate the
            DOI of the article. To create your citation, cite the URL/link of
            this article (from its JSTOR landing page or other web source) or
            create a manual citation.
          </div>
        )}
        {showChromeApp && (
          <div className={css.chromeAppTip}>
            <strong>Tip: </strong>Some websites prevent us from automatically
            grabbing citation information. You can get around this by installing
            the{' '}
            <a
              href={chromeExtensionUrl}
              target="_blank"
              rel="noreferrer noopener"
              onClick={handleHyperLinkClick}
            >
              Bibcitation Chrome Extension
            </a>{' '}
            and running it on the page you want to cite.
            <div
              className="chrome-button-container"
              style={{ marginTop: '30px', marginBottom: '-35px' }}
            >
              <a
                href="https://chrome.google.com/webstore/detail/bibcitation-%E2%80%94-automatic-c/cnjkoanefhlhkagbpofjbafaonfejpgc"
                rel="noreferrer noopener"
                target="_blank"
              >
                <Button
                  newTab
                  size="small"
                  style={{ marginRight: 16 }}
                  onClick={handleButtonClick}
                >
                  <span style={{ display: 'flex' }}>
                    <ChromeSVG />
                    <span style={{ marginLeft: 5 }}>Add to Chrome</span>
                  </span>
                </Button>
              </a>
            </div>
          </div>
        )}
        {!showChromeApp && showFillManually && (
          <div className={css.fillManuallyContainer}>
            <CitationInputFillManuallyButton
              inputType={inputType}
              value={value}
              size="medium"
            />
          </div>
        )}
        {isInvalidURL && (
          <div className={css.validUrlSample}>
            e.g.
            http://www.nytimes.com/2011/10/02/opinion/sunday/meet-the-new-super-people.html
          </div>
        )}
      </div>
      {
        // outside search-error div so it can be pushed to the bottom
        showInvalidURL && (
          <div className={css.invalidUrlContainer}>
            <strong>You entered: </strong>
            <span className={css.invalidUrlText}>{value}</span>
          </div>
        )
      }
    </div>
  );
};

export default SearchError;
