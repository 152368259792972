import React, { useEffect, useState } from 'react';
import * as CitationActions from 'redux/modules/citation-module';
import CitationTypes from 'types/citation-types';
import { Store, useAppDispatch } from 'redux/store';
import citationForms from 'types/citation-forms';
import { useSelector } from 'react-redux';
import { getCitations } from 'redux/selectors/citation-selector';
import Tracking from 'lib/tracking';
import { event } from 'lib/gtag';
import CitationTypePickerList from './citation-type-picker-list';
import IconButton from 'components/common/icon-button';
import Hero from 'components/common/hero';
import Footer from 'components/common/footer';
import {
  getCurrentList,
  isAllowedToEdit,
} from 'web/redux/selectors/citation-list-selector';
import CitationImport from './citation-import';
import PulseLoader from 'components/common/pulse-loader';
import { CitationButton } from './citation-button';
import css from './citation-add-suggestion.module.css';
import cx from 'classnames';

const items: CitationTypes[] = [
  CitationTypes.book,
  CitationTypes.journalArticle,
  CitationTypes.website,
];

const CitationAddSuggestion: React.FC<{ showFooter?: boolean }> = ({
  showFooter = true,
}): JSX.Element => {
  const [expandedTypePicker, setExpandedTypePicker] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const handleClickAdd = (citationTypeKey: CitationTypes): void => {
    Tracking.trackEvent('Picked a citation type', {
      citationType: citationTypeKey,
    });
    event({ action: 'Started adding citation' });
    dispatch(CitationActions.startNewCitation(citationTypeKey));
  };
  const citations = useSelector((state: Store) => getCitations(state));
  const email = useSelector((state: Store) => state.auth.data?.email);
  const currentList = useSelector(getCurrentList);
  const allowedToEdit = isAllowedToEdit(currentList, email);
  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <section className={css.addOutestContainer}>
      {citations.length === 0 && <Hero />}
      <div
        className={cx(
          css.addOuterMiddleContainer,
          citations.length === 0 ? css.noCitations : css.withCitations,
        )}
      >
        {allowedToEdit ? (
          <>
            <div className={css.addCitationText}>Add a citation</div>
            {loading ? (
              <PulseLoader />
            ) : (
              <>
                <div className={css.addOuterContainer}>
                  {items.map((item) => {
                    let type = item;
                    let citationForm = citationForms[type];
                    if (!citationForm) {
                      type = Object.keys(citationForms)[0] as CitationTypes;
                      citationForm = citationForms[type];
                    }

                    return (
                      <CitationButton
                        size="large"
                        handleClickAdd={handleClickAdd}
                        citationForm={citationForm}
                        type={type}
                        key={type}
                      />
                    );
                  })}
                </div>
                {expandedTypePicker ? (
                  <CitationTypePickerList
                    compressed={false}
                    onClickCitationInputType={() =>
                      setExpandedTypePicker(false)
                    }
                    onClickShowMore={() => setExpandedTypePicker(true)}
                    onClickShowLess={() => setExpandedTypePicker(false)}
                  />
                ) : (
                  <>
                    <div className={css.showMoreDesktop}>
                      <IconButton
                        onClick={() => setExpandedTypePicker(true)}
                        label="Show more"
                        icon="more"
                        size="medium"
                        type="brand"
                      />
                    </div>
                    <div className={css.showMoreMobile}>
                      <IconButton
                        onClick={() => setExpandedTypePicker(true)}
                        label="Show more"
                        icon="more"
                        size="small"
                        type="brand"
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <p>List cannot be edited - contact the owner to allow it.</p>
        )}
      </div>
      {showFooter && (
        <>
          <CitationImport text="Import citations" />
          <Footer />
        </>
      )}
    </section>
  );
};

export default CitationAddSuggestion;
