import React from 'react';
import Loader from './loader';
import css from './search-loading.module.css';

interface SearchLoadingProps {
  text?: string;
}

const SearchLoading = (props: SearchLoadingProps): JSX.Element => {
  return (
    <div className={css.searchLoading}>
      <Loader />
      <span className={css.searchLoadingText}>
        {props.text || 'loading preview'}
      </span>
    </div>
  );
};

export default SearchLoading;
