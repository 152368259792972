import React, { useRef } from 'react';
import fileSaver from 'file-saver';
import { CitationData } from 'types/fields';
import CitationTypes from 'types/citation-types';
import * as clipboard from 'clipboard-polyfill';
import getCitations, { getCSLCitationStyle } from 'lib/get-citations';
import stringReplaceAll from 'string-replace-all';
import outputFormats from 'types/output-formats';
import Button from 'components/common/button';
import useClickOutsideDetector from 'lib/hooks/useClickOutsideDetector';
import Tracking from 'lib/tracking';
import { Citation } from 'redux/modules/citation-module';
import { calculateParagraphStylesFromBibliographySettings } from 'lib/paragraphStylesFromBibSettings';
import { useSelector } from 'react-redux';
import { useAppDispatch, Store } from 'redux/store';
import { setGdocsExport } from 'redux/modules/auth-module';
import CopySVG from '../../images/copy.svg';
import DocSVG from '../../images/word.svg';
import HTMLSVG from '../../images/html.svg';
import RISSVG from '../../images/ris.svg';
import BibTextSVG from '../../images/bibtext.svg';
import cleanupRIS from 'lib/cleanup-ris';
import css from './citation-export.module.css';
import { renderText } from 'lib/copy-citation';

export const formatBibliographyAsHtml = (
  citations: Pick<Citation, 'data' | 'citationType'>[],
  gdocsExport: boolean,
): string => {
  const { bibliography } = getCitations(citations, outputFormats.html);
  const { entryspacing, hangingindent, linespacing } = bibliography[0];

  return bibliography[1]
    .map((bibString: string) => {
      return `<p style="${calculateParagraphStylesFromBibliographySettings(
        entryspacing,
        hangingindent,
        linespacing,
        gdocsExport,
      )}">${stringReplaceAll(bibString, '<div', '<span')}</p>`;
    })
    .join('\n')
    .replace(' ', '\n');
};
const htmlBibliographyToText = (biblio: string): string =>
  renderText(biblio) ?? '';

export const copyBibliographyToClipboard = (
  citations: Pick<Citation, 'data' | 'citationType'>[],
  gdocsExport: boolean,
): void => {
  const htmlBibliography = formatBibliographyAsHtml(citations, gdocsExport);
  const item = new clipboard.ClipboardItem({
    'text/html': new Blob([htmlBibliography], { type: 'text/html' }),
    'text/plain': new Blob([htmlBibliographyToText(htmlBibliography)], {
      type: 'text/plain',
    }),
  });
  clipboard.write([item]);
};

interface CitationExportProps {
  citations: { citationType: CitationTypes; data: CitationData }[];
  onClose: (message?: string) => void;
}

export const findCitationRules = (
  name: string,
): { name?: string; text: string; centered: boolean; bolded: boolean } => {
  const commonTitleFormats = [
    {
      name: 'IEEE',
      text: 'References',
      centered: false,
      bolded: false,
    },
    {
      name: 'American Psychological Association',
      text: 'References',
      centered: true,
      bolded: false,
    },
    {
      name: 'Modern Language Association',
      text: 'Works Cited',
      centered: true,
      bolded: false,
    },
    {
      name: 'Turabian',
      text: 'Bibliography',
      centered: true,
      bolded: false,
    },
    {
      name: 'Chicago',
      text: 'Bibliography',
      centered: true,
      bolded: false,
    },
    {
      name: 'Modern Humanities Research Association',
      text: 'References',
      centered: true,
      bolded: false,
    },
  ];
  const found = commonTitleFormats.find((commonTitleFormat) => {
    if (name.includes(commonTitleFormat.name)) return true;
    return false;
  });
  return (
    found || {
      text: 'Your Bibcitation references are below. Paste them into your document',
      centered: false,
      bolded: true,
    }
  );
};

const CitationExport: React.FC<CitationExportProps> = (
  props: CitationExportProps,
): JSX.Element => {
  const exportRef = useRef(null);
  useClickOutsideDetector(exportRef, props.onClose);
  const gdocsExport = useSelector((store: Store) => store.auth.gdocsExport);
  const dispatch = useAppDispatch();
  // RTF documentation http://www.biblioscape.com/rtf15_spec.htm
  // 1 tab = 720 twips
  // \txN: Tab position in twips from the left margin.
  // \fiN: First-line indent (the default is 0).
  // \liN: Left indent (the default is 0).
  // \slN: Space between lines. If this control word is missing or if \sl1000 is used, the line spacing is automatically determined by the tallest character in the line; if N is a positive value, this size is used only if it is taller than the tallest character (otherwise, the tallest character is used); if N is a negative value, the absolute value of N is used, even if it is shorter than the tallest character.
  /* \slmultN: Line spacing multiple.
    Indicates that the current line spacing is a multiple of "Single" line spacing.
    This control word can follow only the \sl control word and works in conjunction with it.
    0 "At Least" or "Exactly" line spacing.
    
    1 Multiple line spacing, relative to "Single."
  */
  // \saN: Space after (the default is 0).
  // \fsN: Font size in half-points (the default is 24).
  // \qc: Centered
  const handleSaveRtf = (): void => {
    Tracking.trackEvent('Outputted citation', {
      type: 'RTF',
    });
    const { bibliography } = getCitations(props.citations, outputFormats.rtf);
    let beforeLineEntry = `{\\pard`;
    let beforeLineAll = '';
    beforeLineAll += `\\sl${240 * bibliography[0].linespacing}`; // add space between lines (double-spaced for APA for instance)
    beforeLineAll += `\\sa${240 * bibliography[0].entryspacing}`;
    beforeLineAll += `\\slmult1`;
    if (bibliography[0].hangingindent) {
      beforeLineEntry += `\\fi-720`; // first line indent reduced by 720
      beforeLineEntry += `\\li720`; // indent of 720
    } else if (bibliography[0]['second-field-align']) {
      const leftPosition = 24 + bibliography[0].maxoffset * 120;
      beforeLineEntry += `\\fi-${leftPosition}`;
      if (bibliography[0]['second-field-align'] !== 'margin') {
        beforeLineEntry += `\\li${leftPosition}`;
        beforeLineEntry += `\\tx${leftPosition}`;
      }
    }
    const appendedRtfs = bibliography[1].reduce(
      (accumulator: string, rtf: string) => {
        return `${accumulator}${beforeLineEntry}${beforeLineAll} ${rtf}\\par}`;
      },
      '',
    );
    const citationRules = findCitationRules(getCSLCitationStyle().name);

    const title = `{\\pard${beforeLineAll}${
      citationRules.centered ? `\\qc` : ''
    } ${citationRules.text}\\par}`;
    const rtfSetup = `\\ansi\\deff0{\\fonttbl {\\f0\\fnil\\fcharset0 Times New Roman;}{\\f1\\fnil\\fcharset2 Symbol;}}{\\colortbl ;}`;

    const rtfFile = `${bibliography[0].bibstart}${rtfSetup}${title}${appendedRtfs}${bibliography[0].bibend}`;

    const blob = new Blob([rtfFile], {
      type: 'application/rtf;charset=utf-8',
    });
    fileSaver.saveAs(blob, 'bibcitation-export.rtf');
    props.onClose('Downloaded!');
  };
  const handleSaveToClipboard = (): void => {
    Tracking.trackEvent('Outputted citation', {
      type: 'clipboard',
    });
    copyBibliographyToClipboard(props.citations, gdocsExport);
    props.onClose('Copied!');
  };
  const handleCopyBibtex = async (): Promise<void> => {
    Tracking.trackEvent('Outputted citation', {
      type: 'BibTeX',
    });
    const Cite = (await import('citation-js')).default;
    const formattedCitations = props.citations.map((citation) => {
      return citation.data;
    });
    const citationRender = new Cite(formattedCitations);
    const bibtexString = citationRender.format('bibtex');
    clipboard.writeText(bibtexString);
    props.onClose('Copied!');
  };

  const handleCopyHtml = (): void => {
    Tracking.trackEvent('Outputted citation', {
      type: 'HTML',
    });
    const { bibliography } = getCitations(props.citations, outputFormats.html);
    const allCitationsString = bibliography[1].join('\n').replace(' ', '\n');
    clipboard.writeText(allCitationsString);
    props.onClose('Copied!');
  };
  const handleExportRIS = async (): Promise<void> => {
    const { format } = await import('web/lib/plugin-ris/ris');
    const cslData: CitationData[] = props.citations.map((citation) => ({
      ...citation.data,
    }));
    // we have to remove undefined fields because `format` has problems with it. it only happens when you newly add a citation, but goes away on refresh
    cslData.forEach((cslObj: CitationData) => {
      Object.keys(cslObj).forEach((key: string) => {
        if (key in cslObj && cslObj[key as keyof CitationData] === undefined) {
          delete cslObj[key as keyof CitationData];
        }
      });
    });

    const cslString = format(cslData);
    if (typeof cslString !== 'string') {
      throw new Error('cslString is not a string.');
    }
    const cleanCslString = cleanupRIS(cslString);
    const blob = new Blob([cleanCslString], {});
    fileSaver.saveAs(blob, 'bibcitation-export.ris');
    props.onClose('Downloaded!');
  };
  return (
    <div ref={exportRef}>
      <div className={css.exportCitationsWrapper}>
        <div className={css.exportCitationsTop}>
          <div className={css.exportCitationsButtonTop}>
            <Button
              type="default"
              fullWidth={true}
              onClick={handleSaveToClipboard}
            >
              <span className={css.buttonLabel}>
                <CopySVG />
                <span> Copy all </span>
              </span>
            </Button>
          </div>
          <div className={css.exportCitationsRadioButtonContainer}>
            <div className={css.exportCitationsRadioButtons}>
              <span className={css.copyLabel}> Copy for: </span>
              <input
                type="radio"
                id="gdocs"
                name="gdocs-export"
                value="gdocs"
                checked={gdocsExport}
                className={css.copyForRadio}
                onChange={(ev) => {
                  if (ev.target.value === 'gdocs') {
                    dispatch(setGdocsExport(true));
                  }
                }}
              />{' '}
              <label htmlFor="gdocs"> Google Docs </label>
              <input
                type="radio"
                id="word"
                name="gdocs-export"
                value="word"
                checked={!gdocsExport}
                className={css.copyForRadio}
                onChange={(ev) => {
                  if (ev.target.value === 'word') {
                    dispatch(setGdocsExport(false));
                  }
                }}
              />{' '}
              <label htmlFor="word">Microsoft Word / Other</label>
            </div>
          </div>
        </div>
        <div className={css.exportCitationsDivider} />
        <div className={css.exportCitationsBottom}>
          <div className={css.exportCitationsLeft}>
            <div className={css.exportCitationsButtonBottom}>
              <Button type="doc" fullWidth={true} onClick={handleSaveRtf}>
                <span className={css.buttonLabel}>
                  <DocSVG />
                  <span> Save as DOC </span>
                </span>
              </Button>
            </div>
            <div className={css.exportCitationsButtonBottom}>
              <Button type="default" fullWidth={true} onClick={handleCopyHtml}>
                <span className={css.buttonLabel}>
                  <HTMLSVG />
                  <span> Copy HTML </span>
                </span>
              </Button>
            </div>
          </div>
          <div className={css.exportCitationsRight}>
            <div className={css.exportCitationsButtonBottom}>
              <Button
                type={'lighter'}
                fullWidth={true}
                onClick={handleCopyBibtex}
              >
                <span className={css.buttonLabel}>
                  <BibTextSVG />
                  <span> Copy BibTeX </span>
                </span>
              </Button>
            </div>
            <div className={css.exportCitationsButtonBottom}>
              <Button type="default" fullWidth={true} onClick={handleExportRIS}>
                <span className={css.buttonLabel}>
                  <RISSVG />
                  <span> Export RIS </span>
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CitationExport;
