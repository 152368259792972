type GtagFunction<T extends unknown[]> = (...args: T) => void;

interface GtagWindow extends Window {
  gtag: (command: string, code?: string, config?: unknown) => void;
}

declare let window: GtagWindow;

const withGtag =
  <T extends unknown[]>(fun: GtagFunction<T>) =>
  (...args: T): void => {
    if (!window.gtag) {
      return;
    }
    fun(...args);
  };

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = withGtag((url: string): void => {
  window.gtag('config', process.env.GA_TRACKING_CODE, {
    page_path: url,
  });
});

interface EventParams {
  action: string;
  category?: string;
  label?: string;
  value?: string;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = withGtag(
  ({ action, category, label, value }: EventParams): void => {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  },
);
