export enum UrlReqError {
  NotFound,
  External,
  Forbidden,
  Malformed,
  Timeout,
  InvalidURL,
  NoError,
  PdfParsingError,
}

export const errorCodes: { [key: string]: string } = {
  [UrlReqError.NotFound]:
    'Sorry, the resource you searched for does not exist on the given page.',
  [UrlReqError.External]:
    'Sorry, the website that you entered was not able to provide a response.',
  [UrlReqError.Timeout]:
    // heroku timeout
    'Sorry, the website that you entered could not provide a response.',
  [UrlReqError.Forbidden]:
    // 400 code via the external source
    'Sorry, the website that you entered was unable to provide a response.',
  [UrlReqError.Malformed]:
    // 400 code via the external source
    'Sorry, the website that you entered was unable to provide a response.',
  [UrlReqError.InvalidURL]:
    // 400 code via our server after not finding the address's IP
    'The URL entered above does not exist',
  [UrlReqError.PdfParsingError]: 'Sorry, we could not process this pdf.',
};
