import Button from 'components/common/button';
import React, { RefObject, useEffect } from 'react';
import cx from 'classnames';
import { mod } from 'lib/mod';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addNlpData } from 'redux/modules/citation-module';
import { NlpData } from 'types/nlp-data';
import { reportGeneralException } from 'lib/sentry';
import { Store } from 'redux/store';
import Loader from 'components/common/loader';
import CitationTypes from 'types/citation-types';
import * as CitationActions from 'redux/modules/citation-module';
import {
  BookResponseItem,
  IndustryIdentifier,
  ResponseItem,
} from 'types/network-responses';
import css from './citation-input-search-results.module.css';
import { removeTags, uniqBy } from 'lib/util';

interface CitationInputSearchResultProps<T> {
  items: T[];
  minimumSearchLength?: number;
  endOfResults?: boolean;
  loading: boolean;
  page: number;
  highlightedResultIndex: number;
  type: string;
  value: string;
  prevHighlightedResultRef: RefObject<HTMLDivElement>;
  renderSearchResult: (item: T) => JSX.Element;
  onClickCitation: (index: number) => void;
  handleShowMore: () => void;
}

const nlpURL = 'https://nlp3.bibcitation.com/titles';

export async function getNlpData(titles: string[]): Promise<NlpData | null> {
  if (titles.length === 0) {
    return null;
  }

  try {
    const results = await axios.post(nlpURL, titles);
    return results.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      reportGeneralException(err.message, { titles });
    }
    return null;
  }
}

const nlpTitleFromBook = (book: BookResponseItem): string => {
  if (!book) {
    // sometimes we have undefined books? TODO: figure out why
    return '';
  }
  // sometimes books have subtitles that need to be appended to the title.
  return book.volumeInfo.title && book.volumeInfo.subtitle
    ? `${book.volumeInfo.title}: ${book.volumeInfo.subtitle}`
    : book.volumeInfo.title;
};
const nlpTitleFromOtherItem = (item: ResponseItem): string => {
  // sometimes journal titles have html tags that need to be removed.
  return item.title && removeTags(item.title[0].trim());
};

const searchResultLoader = (
  <div
    style={{
      marginTop: mod(2),
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      display: 'flex',
    }}
  >
    <Loader />
    <span
      style={{
        marginLeft: mod(3),
        fontWeight: 600,
        fontStyle: 'normal',
      }}
    >
      Loading
    </span>
  </div>
);

const isBookResponseItem = (item: ResponseItem): item is BookResponseItem => {
  return 'volumeInfo' in item;
};

const CitationInputSearchResults = <T extends ResponseItem>({
  items = [],
  highlightedResultIndex,
  type,
  page,
  minimumSearchLength,
  onClickCitation,
  loading,
  value,
  endOfResults,
  renderSearchResult,
  handleShowMore,
  prevHighlightedResultRef,
}: CitationInputSearchResultProps<T>): JSX.Element => {
  const dispatch = useDispatch();
  const nlpData = useSelector((state: Store) => state.citation.citationNlpData);
  const getTitleFromResponseItem = (item: ResponseItem): string => {
    if (isBookResponseItem(item)) {
      return nlpTitleFromBook(item);
    } else {
      return nlpTitleFromOtherItem(item);
    }
  };
  const nlpTitles = items.map(getTitleFromResponseItem);
  const newTitles = nlpTitles
    .filter((title) => !nlpData[title])
    .filter((title) => !!title);

  useEffect(() => {
    if (newTitles.length === 0) {
      return;
    }
    getNlpData(newTitles).then((data) => {
      dispatch(addNlpData(data));
    });
  }, [dispatch, newTitles]);

  let uniqItems = type === 'book' ? uniqBy(items, 'id') : items;
  let filtered = false;
  if (
    type === 'book' &&
    uniqItems.length > 0 &&
    (
      uniqItems[0] as unknown as BookResponseItem
    ).volumeInfo?.industryIdentifiers?.filter(
      (identifier: IndustryIdentifier) => identifier.identifier === value,
    )?.length !== 0
  ) {
    filtered = true;
    uniqItems = [uniqItems[0]];
  }

  return (
    <div>
      {items.length !== 0 && loading && page === 0 && (
        <div className={css.searchInfo}>{searchResultLoader}</div>
      )}
      {!value.startsWith('http') &&
        // We use uniqBy here b/c as of Sep. 2020 Google API returns
        // duplicate results; searching "proust" for example does so
        uniqItems.map((item: T, index: number) => {
          if (!item) return;
          return (
            <div
              key={(item.id || item.DOI) as string}
              role="button"
              tabIndex={0}
              ref={
                highlightedResultIndex === index
                  ? prevHighlightedResultRef
                  : null
              }
              className={cx(
                highlightedResultIndex === index && css.highlighted,
                css.searchResult,
              )}
              onClick={() => onClickCitation(index)}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  onClickCitation(index);
                }
              }}
            >
              {renderSearchResult(item)}
            </div>
          );
        })}

      {type === 'book' && value.startsWith('http') && (
        <div className={css.searchInfo}>
          This looks like a URL. Please enter it in{' '}
          <span
            role="button"
            className={css.linkToWebSearch}
            tabIndex={0}
            onClick={() => {
              dispatch(CitationActions.startNewCitation(CitationTypes.website));
              dispatch(CitationActions.setExistingSearchValue(value));
            }}
            onKeyDown={() =>
              dispatch(CitationActions.startNewCitation(CitationTypes.website))
            }
          >
            website search.
          </span>
        </div>
      )}
      {minimumSearchLength && value.length === minimumSearchLength - 1 && (
        <div className={css.searchInfo}>Please enter more than one letter</div>
      )}

      {items.length === 0 &&
        !loading &&
        value.length >= 2 &&
        !value.startsWith('http') && (
          <div className={css.searchInfo}>
            No results found. Please refine your search and look for typos.
          </div>
        )}

      {items.length === 0 && loading && value.length >= 2 && (
        <div className={css.searchInfo}>{searchResultLoader}</div>
      )}

      {!value.startsWith('http') &&
        items.length !== 0 &&
        !loading &&
        !endOfResults &&
        !filtered && (
          <div className={css.showMoreButtonContainer}>
            <Button size="medium" onClick={handleShowMore}>
              View more results
            </Button>
          </div>
        )}

      {items.length !== 0 && loading && page > 0 && (
        <div className={css.searchInfo}>{searchResultLoader}</div>
      )}
    </div>
  );
};

export default CitationInputSearchResults;
