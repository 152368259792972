import { AxiosResponse } from 'axios';
import useReqLimiter from 'lib/hooks/useReqLimiter';
import { searchJournalMetadataDoi } from 'models/citation-model';
import React, { useState } from 'react';
import CitationTypes from 'types/citation-types';
import { DoiReqError, errorCodes } from 'types/doi-req-error';
import { CitationDataLoose } from 'types/fields';
import JournalSearchSubtypeProps from 'types/journal-search-subtype-props';
import CitationInputSearchResultSingle from './citation-input-search-result-single';
import CitationInputSearchSingle from './citation-input-search-single';

const CitationInputSearchJournalQueryDoi: React.FC<
  JournalSearchSubtypeProps
> = (props): JSX.Element => {
  const { inputVal } = props;

  const [loading, setLoading] = useState(false);
  const [citationData, setCitationData] = useState<CitationDataLoose>();
  const [reqError, setReqError] = useState<DoiReqError>();

  const onChange = (value: string, page: number): void => {
    props.handleChange(value, page);
    setReqError(DoiReqError.NoError);
  };

  const alwaysRunBefore = (): void => {
    setReqError(undefined);
    setLoading(true);
  };

  const reqCallback = async (
    doi: string,
  ): Promise<AxiosResponse<CitationDataLoose>> => {
    return await searchJournalMetadataDoi(doi);
  };

  const resCallback = (res: AxiosResponse<CitationDataLoose>): void => {
    if (res) {
      setLoading(false);

      if (res.data) {
        setCitationData(res.data);
      }
    }
  };
  const onError = (): void => {
    setLoading(false);
    setReqError(DoiReqError.DoiNotIdentified);
  };

  let invalidInput = !inputVal;
  let errorMsg = '';
  let errorFillManually = false;
  if (reqError == DoiReqError.DoiNotIdentified) {
    invalidInput = true;
    errorMsg = errorCodes[DoiReqError.DoiNotIdentified];
    errorFillManually = true;
  }
  useReqLimiter(
    alwaysRunBefore,
    reqCallback,
    resCallback,
    onError,
    { delay: 500, type: 'debounce', dontCallReq: Boolean(invalidInput) },
    [inputVal],
    inputVal,
  );

  const resItem = loading ? { loading: true } : citationData;

  return (
    <CitationInputSearchSingle
      citationType={props.citationType}
      type={CitationTypes.journalArticle}
      item={resItem}
      onClickCitation={() => props.handleClickCitation(citationData)}
      onChange={onChange}
      renderSearchResult={(item: Record<string, unknown>) => (
        <CitationInputSearchResultSingle item={item} />
      )}
      error={errorMsg}
      errorFillManually={errorFillManually}
      placeholder={props.placeholder}
      existingValue={props.inputVal}
      inputType="DOI"
      uploadButton={props.uploadButton}
    />
  );
};

export default CitationInputSearchJournalQueryDoi;
