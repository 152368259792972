import React, { useState } from 'react';
import * as CitationActions from 'redux/modules/citation-module';
import * as CitationListModel from '../../models/citation-list-model';
import { Store, useAppDispatch } from 'redux/store';
import Tracking from 'lib/tracking';
import { JournalSearchMethod } from 'types/journal-search-method';
import { CitationDataLoose } from 'types/fields';
import CitationInputSearchJournalQueryGeneral from './citation-input-search-journal-query-general';
import CitationInputSearchJournalQueryDoi from './citation-input-search-journal-query-doi';
import CitationInputSearchJournalQueryUrl from './citation-input-search-journal-query-url';
import isUrl from 'lib/is-url';
import citationTypeToCSL from 'lib/citation-type-to-csl';
import CSLCitationTypes from 'types/csl-citation-types';
import UploadButton from 'components/common/upload-button';
import { useSelector, useStore } from 'react-redux';
import {
  capitalizeSubtitles,
  isTitleCase,
  isUpperCase,
  toSentenceCase,
} from 'lib/string-formatting';
import NameCase from 'lib/namecase';
import CitationTypes from 'types/citation-types';
import autoScrollToElementWithId from 'lib/auto-scroll-to-element-with-id';
import { createUploadRequest } from 'models/pdf-upload-model';
import axios from 'axios';

interface CitationInputSearchJournalProps {
  citationType: CitationTypes;
  setShowInfo: (showInfo: boolean) => void;
}

export type PdfUpload =
  | { type: 'initializing' }
  | { type: 'uploading'; progress: number }
  | { type: 'error'; text: string };

const CitationInputSearchJournal = (
  props: CitationInputSearchJournalProps,
): JSX.Element | null => {
  const [value, setValue] = useState<string>('');
  const [pdfUpload, setPdfUpload] = useState<PdfUpload | undefined>();
  const [page, setPage] = useState<number>(0); // only used for general search
  const currentCitationStyle = useSelector(
    (state: Store) => state.citation.citationStyle,
  );
  const nlpData = useSelector((state: Store) => state.citation.citationNlpData);

  const [searchType, setSearchType] = useState<JournalSearchMethod>('general');
  const dispatch = useAppDispatch();
  const store = useStore();
  const isAPA = currentCitationStyle.name.includes(
    'American Psychological Association',
  );
  const handleChange = async (newVal: string, page?: number): Promise<void> => {
    if (newVal) newVal = newVal.trim();
    setValue(newVal);
    setPdfUpload(undefined);

    if (isDoi(newVal)) {
      setSearchType('DOI');
    } else if (isUrl(newVal)) {
      setSearchType('url');
    } else {
      if (page !== undefined) setPage(page);
      setSearchType('general');
    }
  };

  const isDoi = (value: string): boolean => {
    return value.startsWith('10.');
  };

  const handleClickCitation = (
    citationData: CitationDataLoose | undefined,
  ): void => {
    let CSLType = CSLCitationTypes['article-journal'];
    let editedTitle = false;

    if (citationData) {
      if (citationData.title && isAPA) {
        if (isTitleCase(citationData.title)) {
          citationData.title = toSentenceCase(
            citationData.title,
            nlpData[citationData.title],
          );
        } else {
          citationData.title = capitalizeSubtitles(citationData.title); // less aggressive than the first
        }
        editedTitle = true;
      }
      if (citationData.author) {
        citationData.author = citationData.author.map((author) => {
          if (author.family && isUpperCase(author.family)) {
            author.family = NameCase(author.family, { individualFields: true });
          }
          if (author.given && isUpperCase(author.given)) {
            author.given = NameCase(author.given);
          }
          if (author.literal && isUpperCase(author.literal)) {
            author.literal = NameCase(author.literal);
          }
          return author;
        });
      }

      dispatch(CitationActions.editCurrentCitation(citationData));
      if (citationData.regularType) {
        CSLType = citationTypeToCSL(citationData.regularType);
        dispatch(CitationActions.editCurrentCitation({ type: CSLType }));
        dispatch(CitationActions.setCitationType(citationData.regularType));
      }
    }
    Tracking.trackEvent('Finished editing a citation', {
      citationType: CSLType,
    });

    if ((window.innerWidth || document.documentElement.clientWidth) > 590) {
      if (editedTitle) {
        props.setShowInfo(true);
      }
      dispatch(CitationActions.fillManually());
    } else {
      dispatch(CitationActions.finishEditingCitation());
    }
    autoScrollToElementWithId('last-made-citation');
    CitationListModel.saveCurrentList(store);
  };

  const throwPdfUploadError =
    (state: 'uploading' | 'initializing') => (original: Error) => {
      const error = Object.create(new Error(`Failed ${state}`));
      error.original = original;
      error.pdfUpload = true;
      throw error;
    };

  const handlePdfUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const {
      target: { files },
    } = e;
    if (files === null || files.length === 0) return;
    try {
      setPdfUpload({ type: 'initializing' });
      setValue('');
      setSearchType('url');
      const file = files[0];
      const { uploadUrl, publicUrl } = await createUploadRequest()
        .then((res) => res.data)
        .catch(throwPdfUploadError('initializing'));
      setPdfUpload({ type: 'uploading', progress: 0 });
      await axios
        .put(uploadUrl, file, {
          onUploadProgress: ({ loaded, total }) => {
            const progress = Math.floor((loaded / total) * 100);
            setPdfUpload({ type: 'uploading', progress });
          },
        })
        .catch(throwPdfUploadError('uploading'));
      setValue(publicUrl);
    } catch (error: any) {
      const message = error.pdfUpload ? error.message : 'Something went wrong';
      console.error(error.original || error);
      setPdfUpload({
        type: 'error',
        text: `${message}. Try again later.`,
      });
    }
  };

  const uploadButton = (
    <UploadButton
      label="Upload PDF"
      onChange={handlePdfUpload}
      accept="application/pdf"
    />
  );

  const placeholder = 'Title, author(s), or DOI';

  if (searchType === 'general') {
    return (
      <CitationInputSearchJournalQueryGeneral
        citationType={props.citationType}
        handleChange={handleChange}
        handleClickCitation={handleClickCitation}
        inputVal={value}
        placeholder={placeholder}
        pageNum={page}
        uploadButton={uploadButton}
      />
    );
  } else if (searchType === 'DOI') {
    return (
      <CitationInputSearchJournalQueryDoi
        citationType={props.citationType}
        handleChange={handleChange}
        handleClickCitation={handleClickCitation}
        inputVal={value}
        placeholder={placeholder}
        uploadButton={uploadButton}
      />
    );
  } else if (searchType === 'url') {
    const loadingMessage =
      pdfUpload === undefined || pdfUpload.type === 'error'
        ? undefined
        : pdfUpload.type === 'initializing'
        ? 'Initializing'
        : pdfUpload.progress === 100
        ? undefined
        : `Uploading ${pdfUpload.progress}%`;
    const errorMessage =
      pdfUpload?.type === 'error' ? pdfUpload.text : undefined;
    return (
      <CitationInputSearchJournalQueryUrl
        citationType={props.citationType}
        handleChange={handleChange}
        handleClickCitation={handleClickCitation}
        inputVal={value}
        uploadButton={uploadButton}
        placeholder={placeholder}
        loadingMessage={loadingMessage}
        errorMessage={errorMessage}
        hideUrl={!!pdfUpload}
        skipUrlValidation={!!pdfUpload}
      />
    );
  }
  return null;
};

export default CitationInputSearchJournal;
