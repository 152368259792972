import isVowel from 'lib/is-vowel';
import React from 'react';
import { CitationInputType as CitationSingleInputType } from 'types/citation-input-type';
import CitationTypes from 'types/citation-types';
import CitationTypesText from 'types/citation-types-text';
import CitationInputFillManuallyButton from './citation-input-fill-manually-button';
import css from './citation-input-header.module.css';
import cx from 'classnames';

interface CitationInputHeaderProps {
  citationType: CitationTypes;
  value?: string;
  inputType?: CitationSingleInputType;
  mainHeading?: string;
  uploadButton?: JSX.Element;
}

const CitationInputHeader: React.FC<CitationInputHeaderProps> = (
  props,
): JSX.Element => {
  let mainHeading = props.mainHeading;
  if (!mainHeading) {
    const dontIncludeArticle = props.citationType !== 'website'; // look at this
    let article = '';
    if (!dontIncludeArticle) {
      const firstCharacterOfType = props.citationType.charAt(0);
      const useAn = isVowel(firstCharacterOfType);
      article = useAn ? 'an' : 'a';
    }

    mainHeading = `Find ${article} ${CitationTypesText[props.citationType]}`;
  }

  return (
    <div className={css.citationsSearchHeader}>
      <h2 className={cx(css.citationsSearchTitle, 'label-medium')}>
        {mainHeading}
      </h2>
      {props.uploadButton && (
        <span className={css.uploadContainer}>
          <p className={cx(css.citationsSearchOr, 'paragraph-medium')}>or</p>
          {props.uploadButton}
        </span>
      )}
      <p className={cx(css.citationsSearchOr, 'paragraph-medium')}>or</p>
      <CitationInputFillManuallyButton
        value={props.value}
        inputType={props.inputType}
      />
    </div>
  );
};

export default CitationInputHeader;
