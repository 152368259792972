import React, { useRef } from 'react';
import usePrevious from 'lib/hooks/usePrevious';
import FormLabel from './form-label';
import css from './input-page-range.module.css';

interface InputPageProps {
  citationId: string;
  name?: string;
  value?: string;
  onChange: (newValue: string) => void;
  isRequired?: boolean;
  isPrimary?: boolean;
  isFocused?: boolean;
}

const InputPageRange: React.FC<InputPageProps> = (
  props: InputPageProps,
): JSX.Element => {
  const fromPage = useRef<HTMLInputElement>(null);
  const toPage = useRef<HTMLInputElement>(null);

  const prevCitationId = usePrevious(props.citationId);
  if (prevCitationId !== props.citationId) {
    if (fromPage.current) fromPage.current.value = '';
    if (fromPage.current) fromPage.current.value = '';
  }

  const handleChange = (): void => {
    const pageTo = fromPage.current?.value && parseInt(fromPage.current.value);
    const pageFrom = toPage.current?.value && parseInt(toPage.current.value);
    const pageRange =
      (pageTo && pageFrom !== pageTo
        ? pageFrom + '-' + pageTo
        : pageFrom
      )?.toString() || '';
    props.onChange(pageRange);
  };

  return (
    <div>
      <div className={css.inputsContainer}>
        <div className={css.inputContainer}>
          <FormLabel htmlFor={`${props.name}-from`}>Page from</FormLabel>
          <input
            ref={toPage}
            type="number"
            data-hj-allow
            data-clarity-unmask="true"
            id={`${props.name}-to-page`}
            name={`${props.name}-to-page`}
            value={props.value && props.value.split('-')[0]}
            onChange={handleChange}
          />
        </div>

        <div className={css.inputContainer}>
          <FormLabel htmlFor={`${props.name}-to`}>Page to</FormLabel>
          <input
            ref={fromPage}
            type="number"
            data-hj-allow
            data-clarity-unmask="true"
            placeholder={props.isRequired ? 'Required' : ''}
            id={`${props.name}-from-page`}
            name={`${props.name}-from-page`}
            value={props.value && props.value.split('-')[1]}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default InputPageRange;
