import React, { ChangeEvent, useEffect, useState } from 'react';
import * as CitationActions from 'redux/modules/citation-module';
import { Store, useAppDispatch } from 'redux/store';
import CloseSVG from 'static/svgs/icon/16/close.svg';
import SearchIcon from 'static/svgs/icon/17/search.svg';
import Button from 'components/common/button';
import Input from 'components/common/input';
import SearchLoading from 'components/common/search-loading';
import SearchError from 'components/common/search-error';
import Tracking from 'lib/tracking';
import { CitationInputType } from 'types/citation-input-type';
import CitationTypes from 'types/citation-types';
import CitationInputHeader from './citation-input-header';
import { getNlpData } from './citation-input-search-results';
import { LoginPageError } from 'lib/url-req-error-helpers';
import { useRouter } from 'next/router';
import yn from 'yn';
import css from './citation-input-search-single.module.css';
import cx from 'classnames';
import { useSelector } from 'react-redux';

interface CitationInputSearchSingleProps {
  citationType: CitationTypes;
  item?: Record<string, any>;
  onChange: (value: string, page: number) => void;
  onClickCitation: () => void;
  placeholder: string;
  renderSearchResult: (item: Record<string, unknown>) => JSX.Element;
  error?: string | JSX.Element;
  errorFillManually?: boolean;
  type: CitationTypes;
  inputType?: CitationInputType;
  mainHeading?: string;
  existingValue?: string;
  uploadButton?: JSX.Element;
  hideInvalidUrl?: boolean;
  inputDisabled?: boolean;
}

const CitationInputSearchSingle: React.FC<CitationInputSearchSingleProps> = (
  props: CitationInputSearchSingleProps,
): JSX.Element => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [value, setValue] = useState('');

  useEffect(() => {
    if (props.item?.title) {
      getNlpData([props.item?.title]).then((data) => {
        dispatch(CitationActions.addNlpData(data));
      });
    }
  }, [dispatch, props.item?.title]);

  const handleClickExit = (): void => {
    dispatch(CitationActions.deleteCurrentCitation());
  };

  const handleChangeValue = (newValue: string): void => {
    setValue(newValue);
    props.onChange(newValue, 0);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Escape') {
      handleClickExit();
    }
  };

  const onClickCitation = (): void => {
    Tracking.trackEvent('Clicked add citation', {
      type: 'single result',
    });
    props.onClickCitation();
  };

  const storeExistingValue = useSelector(
    (state: Store) => state.citation.existingSearchValue,
  );
  const { existingValue } = props;
  const curVal = storeExistingValue || existingValue || value;
  if (storeExistingValue) {
    props.onChange(curVal, 0);
    setValue(curVal);
    dispatch(CitationActions.setExistingSearchValue(undefined));
  }

  const renderSearchOutput = (): JSX.Element | undefined => {
    let toReturn: JSX.Element | undefined = undefined;
    let showAddButton = false;
    const lowercaseTitle = props.item?.title?.toLowerCase();

    if (props.error) {
      toReturn = (
        <SearchError
          errorText={props.error}
          showFillManually={props.errorFillManually}
          inputType={props.inputType}
          value={curVal}
          hideInvalidUrl={props.hideInvalidUrl}
        />
      );
    } else if (!props.item) {
      return <div className={css.noSearchResults}></div>;
    } else if (
      !yn(process.env.CHROME) &&
      lowercaseTitle &&
      (lowercaseTitle.includes('sign in') ||
        lowercaseTitle.includes('authentication') ||
        lowercaseTitle.includes('login') ||
        lowercaseTitle.includes('log in'))
    ) {
      toReturn = <LoginPageError />;
    } else if (props.item && props.item.loading) {
      toReturn = <SearchLoading text={props.item.loading.text} />;
    } else {
      toReturn = props.renderSearchResult(props.item);
      showAddButton = true;
    }
    return (
      <div className={css.searchResult}>
        {toReturn}
        {showAddButton && (
          <div className={css.buttonContainer}>
            <Button onClick={onClickCitation}>Continue</Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={css.citationInputSearchSingle}>
      <div
        className={css.citationInputSearchBack}
        style={{ position: 'absolute', top: -50 }}
      >
        <Button
          type="link"
          size="small"
          style={{ color: 'var(--brand-strong)' }}
          onClick={() => handleClickExit()}
        >
          &lt; Back
        </Button>
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={css.citationsSearch} onKeyDown={handleInputKeyDown}>
        <CitationInputHeader
          citationType={props.citationType}
          value={curVal}
          inputType={props.inputType}
          mainHeading={props.mainHeading}
          uploadButton={props.uploadButton}
        />
        <button
          className={cx(
            css.citationsSearchClose,
            router.pathname === '/word' ? css.word : css.default,
          )}
          onClick={() => handleClickExit()}
          aria-label={`Close ${props.type} search`}
        >
          <CloseSVG />
        </button>

        <div className={css.citationsSearchInput}>
          <Input
            icon={<SearchIcon />}
            autoFocus
            name="search"
            aria-label={props.placeholder}
            placeholder={props.placeholder}
            value={curVal}
            disabled={props.inputDisabled}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              handleChangeValue(ev.target.value);
            }}
          />
        </div>
      </div>
      {renderSearchOutput()}
    </div>
  );
};

export default CitationInputSearchSingle;
