import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import * as CitationActions from 'redux/modules/citation-module';
import { useAppDispatch } from 'redux/store';
import CloseSVG from 'static/svgs/icon/16/close.svg';
import SearchIcon from 'static/svgs/icon/17/search.svg';
import Input from 'components/common/input';
import CitationInputSearchResults from './citation-input-search-results';
import CitationTypesText from 'types/citation-types-text';
import CitationTypes from 'types/citation-types';
import CitationInputHeader from './citation-input-header';
import Button from 'components/common/button';
import { useRouter } from 'next/router';
import { ResponseItem } from 'types/network-responses';
import css from './citation-input-search.module.css';
import cx from 'classnames';

interface CitationInputSearchProps<T extends ResponseItem> {
  citationType: CitationTypes;
  initialValue?: string;
  items: T[];
  minimumSearchLength?: number;
  onChange: (value: string, page: number) => void;
  onClickCitation: (index: number) => void;
  loading: boolean;
  placeholder: string;
  renderSearchResult: (item: T) => JSX.Element;
  type: CitationTypes;
  endOfResults?: boolean;
  existingValue?: string;
  uploadButton?: JSX.Element;
}

const SCROLL_AMOUNT_ON_KEY_DOWN = 0;
const SCROLL_AMOUNT_ON_KEY_UP = 300;

const CitationInputSearch = <T extends ResponseItem>(
  props: CitationInputSearchProps<T>,
): JSX.Element => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [value, setValue] = useState('');
  const [page, setPage] = useState(0);
  const [highlightedResultIndex, setHighlightedResultIndex] = useState(-1);

  const prevHighlightedRef = useRef<HTMLDivElement>(null);

  const handleClickExit = (): void => {
    dispatch(CitationActions.deleteCurrentCitation());
  };

  const handleShowMore = (): void => {
    props.onChange(value, page + 1);
    setPage(page + 1);
  };
  const handleChangeValue = (newValue: string): void => {
    setValue(newValue);
    setHighlightedResultIndex(-1);
    setPage(0);
    props.onChange(newValue, 0);
    scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };
  const handleNavigationKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Down' || e.key === 'ArrowDown') {
      if (highlightedResultIndex >= props.items.length - 1) {
        if (props.endOfResults) return;
        handleShowMore();
        return;
      }
      setHighlightedResultIndex(highlightedResultIndex + 1);
      if (!prevHighlightedRef || !prevHighlightedRef.current) return;
      scrollTo({
        left: 0,
        top: prevHighlightedRef.current.offsetTop + SCROLL_AMOUNT_ON_KEY_DOWN,
        behavior: 'smooth',
      });
    } else if (e.key === 'Up' || e.key === 'ArrowUp') {
      if (highlightedResultIndex === -1) return;
      setHighlightedResultIndex(highlightedResultIndex - 1);
      if (!prevHighlightedRef || !prevHighlightedRef.current) return;
      scrollTo({
        left: 0,
        top: prevHighlightedRef.current.offsetTop - SCROLL_AMOUNT_ON_KEY_UP,
        behavior: 'smooth',
      });
    } else if (e.key === 'Enter') {
      if (
        props.items &&
        highlightedResultIndex < props.items.length &&
        highlightedResultIndex >= 0
      ) {
        props.onClickCitation(highlightedResultIndex);
        return;
      }
    }
  };
  const handleInputKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Up' || e.key === 'ArrowUp') {
      e.preventDefault();
    } else if (e.key === 'Escape') {
      handleClickExit();
    }
  };

  useEffect(() => {
    if (props.initialValue) {
      handleChangeValue(props.initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValue]); // exclude handleChangeValue from deps

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={css.citationInputSearch}
      onKeyDown={handleNavigationKeyDown}
    >
      <div
        className={css.citationInputSearchBack}
        style={{ position: 'absolute', top: -50 }}
      >
        <Button
          type="link"
          size="small"
          style={{ color: 'var(--brand-strong)' }}
          onClick={() => handleClickExit()}
        >
          &lt; Back
        </Button>
      </div>
      <div className={css.citationsSearch}>
        <CitationInputHeader
          citationType={props.citationType}
          uploadButton={props.uploadButton}
        />
        <button
          className={cx(
            css.citationsSearchClose,
            router.pathname === '/word'
              ? css.horizontalPositioningWord
              : css.horizontalPositioningNonWord,
          )}
          onClick={() => handleClickExit()}
          aria-label={`Close ${CitationTypesText[props.type]} search`}
        >
          <CloseSVG />
        </button>
        <div className={css.citationsSearchInput}>
          <Input
            onKeyDown={handleInputKeyDown}
            autoComplete="off"
            icon={<SearchIcon />}
            autoFocus
            name="search"
            placeholder={props.placeholder || `Search ${props.type}s`}
            value={value || props.existingValue}
            data-hj-allow
            data-clarity-unmask="true"
            aria-label={props.placeholder}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              handleChangeValue(ev.target.value);
            }}
          />
        </div>
      </div>
      <CitationInputSearchResults
        items={props.items}
        type={props.type}
        page={page}
        minimumSearchLength={props.minimumSearchLength}
        renderSearchResult={props.renderSearchResult}
        loading={props.loading}
        endOfResults={props.endOfResults}
        onClickCitation={props.onClickCitation}
        highlightedResultIndex={highlightedResultIndex}
        value={value}
        handleShowMore={handleShowMore}
        prevHighlightedResultRef={prevHighlightedRef}
      />
    </div>
  );
};

export default CitationInputSearch;
