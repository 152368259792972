import React, { useRef } from 'react';
import Button from './button';

interface UploadButtonProps {
  accept?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

const UploadButton: React.FC<UploadButtonProps> = (props): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  const triggerFileInput = (): void => {
    if (!inputRef || !inputRef.current) return;
    inputRef.current.click();
  };

  return (
    <>
      <input
        aria-labelledby="uploadPDFBtn"
        type="file"
        hidden
        ref={inputRef}
        accept={props.accept || ''}
        onChange={props.onChange}
      />
      <Button type="brandDark" size="small" onClick={triggerFileInput}>
        {props.label}
      </Button>
    </>
  );
};

export default UploadButton;
