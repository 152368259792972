import React, { useEffect, useState } from 'react';
import CitationExport from './citation-export';
import * as CitationListActions from 'redux/modules/citation-list-module';
import * as CitationActions from 'redux/modules/citation-module';
import { useAppDispatch, Store } from 'redux/store';
import * as CitationListModel from 'models/citation-list-model';
import { setCSLCitationStyle } from 'lib/get-citations';
import { getCitationStyleXML } from 'models/citation-model';
import { mod } from 'lib/mod';
import Button from 'components/common/button';
import CitationOutputStyle from './citation-output-style';
import { useSelector, useStore } from 'react-redux';
import Tracking from 'lib/tracking';
import CitationOutputBibliography from './citation-output-bibliography';
import CitationImport from './citation-import';
import { useRouter } from 'next/router';
import yn from 'yn';
import css from './citation-output.module.css';
import { Citation } from 'redux/modules/citation-module';

const ALERT_FADE_DELAY = 2000;
const ALERT_MESSAGE_TIMEOUT = ALERT_FADE_DELAY + 1000;

type CitationOutputProps = {
  forceCitationStyle?: string;
};

const LargeCopiedCheck: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
): JSX.Element => (
  <svg width="17.5" height="14" fill="none" viewBox="0 0 17.5 14">
    <path
      d="m0.875 7 5.25 5.25 10.5 -10.5"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);

const CitationOutput: React.FC<CitationOutputProps> = ({
  forceCitationStyle,
}): JSX.Element => {
  const [exporting, setExporting] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [loadingCitationStyle, setLoadingCitationStyle] = useState(false);

  const store = useStore();
  const citationStyle = useSelector(
    (state: Store) => state.citation.citationStyle,
  );
  const undoList = useSelector((state: Store) => state.citation.undoList);
  const mostRecentlyUndoneCitation = undoList[undoList.length - 1];

  const citations = useSelector((state: Store) => state.citation.citations);

  const dispatch = useAppDispatch();
  const router = useRouter();

  const handleChangeCitationStyleNoRouting = async (
    citationStyleKey: string,
  ): Promise<void> => {
    const citationStylePromise = getCitationStyleXML(citationStyleKey).then();
    setLoadingCitationStyle(true);
    const citationStyleXML = await citationStylePromise;
    const citationStyle = {
      name: citationStyleKey,
      xml: citationStyleXML.data,
    };
    Tracking.trackEvent('Switched citation style', {
      to: citationStyleKey,
    });
    setCSLCitationStyle(citationStyle); // for internal data and citation generation; reads xml
    dispatch(CitationListActions.setCitationStyle(citationStyle)); // for external ui info about current citation type
    setLoadingCitationStyle(false); // above line might not have run; but should be very small difference/not matter
    await CitationListModel.saveCurrentList(store);
  };

  const handleChangeCitationStyle = async (
    citationStyleKey: string,
  ): Promise<void> => {
    await handleChangeCitationStyleNoRouting(citationStyleKey);
    if (
      !yn(process.env.CHROME) &&
      !yn(process.env.GDOCS) &&
      forceCitationStyle &&
      router.pathname !== '/'
    ) {
      router.replace('/', '/', { shallow: true });
    }
  };

  const handleExportClick = (): void => {
    Tracking.trackEvent('Clicked export button');
    setExporting(true);
    CitationListModel.saveCurrentList(store);
  };

  const handleCloseClick = (message?: string): void => {
    setExporting(false);
    if (message && alertMessage !== message) {
      setAlertMessage(message);
      setTimeout(() => setAlertMessage(''), ALERT_MESSAGE_TIMEOUT);
    }
  };

  const handleUndo = (citation: Citation) => {
    dispatch(CitationActions.undoDeleteCitation(citation));
    CitationListModel.saveCurrentList(store);
    Tracking.trackEvent('Undid a deletion');
  };

  useEffect(() => {
    if (
      !yn(process.env.CHROME) &&
      !yn(process.env.GDOCS) &&
      forceCitationStyle
    ) {
      const setCitationStyle = async () =>
        handleChangeCitationStyleNoRouting(forceCitationStyle);
      setCitationStyle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only run on mount

  return (
    <>
      {alertMessage !== '' && (
        <div className={css.alertMessage}>
          <LargeCopiedCheck />
          <p>{alertMessage}</p>
        </div>
      )}

      {exporting && (
        <div className={css.viewCitationsHeaderExportWrapper}>
          <CitationExport citations={citations} onClose={handleCloseClick} />
        </div>
      )}
      <div className={css.viewCitationsHeaderContent}>
        <CitationOutputStyle
          citationStyle={citationStyle}
          onChangeCitationStyle={handleChangeCitationStyle}
          loadingCitationStyle={loadingCitationStyle}
        />
        {process.env.GDOCS && (
          <div style={{ marginRight: mod(1) }}>
            <CitationImport />
          </div>
        )}
        <div className={css.exportButton}>
          {exporting ? (
            <Button type="light" onClick={() => handleCloseClick()}>
              Close
            </Button>
          ) : (
            <Button
              size="big"
              disabled={citations.length === 0}
              onClick={handleExportClick}
            >
              Copy &amp; export
            </Button>
          )}
        </div>
      </div>
      <div className={css.viewCitationsOuterContent}>
        <div id="citation-list" className={css.viewCitationsContent}>
          <CitationOutputBibliography
            citations={citations}
            citationStyle={citationStyle}
          />
          {undoList.length > 0 && (
            <div className={css.undoButtonContainer}>
              Citation deleted
              <button
                className={css.undoButton}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleUndo(mostRecentlyUndoneCitation);
                }}
              >
                Undo
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CitationOutput;
