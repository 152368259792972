import React, { useRef } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Store } from 'redux/store';
import {
  getCurrentList,
  isAllowedToEdit,
} from 'redux/selectors/citation-list-selector';
import useClickOutsideDetector from 'lib/hooks/useClickOutsideDetector';
import { addCitationsFromListImport } from 'redux/modules/citation-module';
import { saveCurrentList } from 'models/citation-list-model';
import reportException, { reportGeneralException } from 'lib/sentry';
import axios from 'axios';
import createApiUrl from 'lib/create-api-url';
import css from './import-list-options.module.css';

interface ImportListOptionsProps {
  showOptions: boolean;
  setShowOptions: (value: boolean) => void;
}
const ImportListOptions: React.FC<ImportListOptionsProps> = ({
  showOptions,
  setShowOptions,
}) => {
  const email = useSelector((state: Store) => state.auth.data?.email);
  const currentList = useSelector(getCurrentList);
  const allowedToEdit = isAllowedToEdit(currentList, email);
  const importListOptionRef = useRef<HTMLDivElement>(null);
  const importBibTexRef = useRef<HTMLInputElement>(null);
  const importRISRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const store = useStore();

  const handleShowOptions = (): void => {
    setShowOptions(!showOptions);
  };
  const handleImportClick = (
    refToBeClicked: React.RefObject<HTMLInputElement>,
  ): void => {
    refToBeClicked.current?.click();
    handleShowOptions();
  };
  const handleRISImport = async (
    e: React.FormEvent<HTMLInputElement>,
  ): Promise<void> => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target?.result;
      if (typeof text == 'string') {
        reportGeneralException('Importing RIS', { text });
        try {
          const res = await axios.post(
            createApiUrl('/citation-parsing/ris/import'),
            { text },
          );
          dispatch(addCitationsFromListImport(res.data.citations));
          saveCurrentList(store);
        } catch (e) {
          alert('Invalid RIS file');
          reportGeneralException('Error parsing RIS file', { text });
        }
      } else {
        alert('Invalid RIS file');
        reportException('Invalid RIS file');
      }
    };
    const inputElem = e.target as HTMLInputElement;
    if (inputElem.files) {
      reader.readAsText(inputElem.files[0]);
    }
  };
  const handleBibTexImport = async (e: any): Promise<void> => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target?.result;
      if (typeof text == 'string') {
        reportGeneralException('Importing BibTeX', { text });
        try {
          const res = await axios.post(
            createApiUrl('/citation-parsing/bibtex/import'),
            { text },
          );
          dispatch(addCitationsFromListImport(res.data.citations));
          saveCurrentList(store);
        } catch (e) {
          alert('Invalid BibTeX file');
          reportGeneralException('Error parsing BibTeX file', { text });
        }
      } else {
        alert('Invalid BibTeX file');
        reportException('Invalid BibTeX file');
      }
    };
    reader.readAsText(e.target.files[0]);
  };
  useClickOutsideDetector(importListOptionRef, () => setShowOptions(false));
  return (
    <div
      className={css.wrapper}
      tabIndex={0}
      role="button"
      ref={importListOptionRef}
      onKeyPress={handleShowOptions}
    >
      <span
        className={cx(
          css.dropdownListContainer,
          showOptions && css.dropdownListContainerActive,
        )}
      >
        <ul className={css.dropdownList}>
          {allowedToEdit && (
            <>
              <li className={css.dropdownListItem}>
                <button onClick={() => handleImportClick(importRISRef)}>
                  Import RIS file
                  <input
                    type="file"
                    accept=".ris"
                    style={{ display: 'none' }}
                    ref={importRISRef}
                    onChange={handleRISImport}
                  />
                </button>
              </li>
              <hr className={css.dropdownListItemLine} />
              <li className={css.dropdownListItem}>
                <button onClick={() => handleImportClick(importBibTexRef)}>
                  Import BibTeX file
                  <input
                    type="file"
                    accept=".bib"
                    style={{ display: 'none' }}
                    ref={importBibTexRef}
                    onChange={handleBibTexImport}
                  />
                </button>
              </li>
            </>
          )}
          {!allowedToEdit && (
            <li className={css.dropdownListItem}>
              You cannot edit this list. Contact the owner for access.
            </li>
          )}
        </ul>
      </span>
    </div>
  );
};

export default ImportListOptions;
