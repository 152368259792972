import React from 'react';
import css from './pulse-loader.module.css';

const PulseLoader = (): JSX.Element => {
  return (
    <div className={css.skFlow}>
      {Array(3)
        .fill(null)
        .map((_, idx) => (
          <div key={idx} className={css.skFlowDot}></div>
        ))}
    </div>
  );
};

export default PulseLoader;
