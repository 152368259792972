import React, { useState, useRef } from 'react';
import * as CitationActions from 'redux/modules/citation-module';
import { Store, useAppDispatch } from 'redux/store';
import { event } from 'lib/gtag';
import { breakpoints, colors, defaults, text } from 'css/variables';
import { mod } from 'lib/mod';
import CitationTypePickerSearch from './citation-type-picker-search';
import citationForms from 'types/citation-forms';
import Tracking from 'lib/tracking';
import { CitationButton } from './citation-button';
import CitationTypes from 'types/citation-types';
import Link from 'next/link';
import { experiments } from 'redux/modules/ab-test-module';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import css from './citation-type-picker-dropdown.module.css';

interface CitationTypePickerDropDownProps {
  onClose: () => void;
}

const CitationTypePickerDropDown: React.FC<CitationTypePickerDropDownProps> = (
  props: CitationTypePickerDropDownProps,
) => {
  const citationTypeList = useRef<HTMLDivElement | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useAppDispatch();

  const citationTypes = Object.keys(citationForms);

  const handleCitationTypeSelect = (citationTypeKey: string) => {
    Tracking.trackEvent('Picked a citation type', {
      citationType: citationTypeKey,
    });
    event({ action: 'Started adding citation' });
    dispatch(CitationActions.startNewCitation(citationTypeKey));
    props.onClose();
  };

  const experiment = useSelector(
    (state: Store) =>
      state.experiments[
        experiments['2023-10-20-search-empty-contact-us-cta'].id
      ],
  );

  function findCitationTypes(
    citationTypes: string[],
    searchTerm: string,
  ): string[] {
    const splitSearchTerm: string[] = searchTerm.toLowerCase().split(' ');
    const filteredCitations = citationTypes.filter((citationType) => {
      return splitSearchTerm.every((term) =>
        citationType.toLowerCase().includes(term),
      );
    });
    return filteredCitations;
  }

  const citationTypesToUse = searchTerm
    ? findCitationTypes(citationTypes, searchTerm)
    : citationTypes;

  return (
    <div className={css.citationTypePickerDropdown}>
      <div className={css.citationTypePickerInner}>
        <div className={css.citationTypePickerHeader}>
          <CitationTypePickerSearch
            searchTerm={searchTerm}
            onClose={props.onClose}
            onChangeSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
          />
        </div>

        {citationTypesToUse.length === 0 ? (
          <div
            className={cx(
              css.contactUsCta,
              experiment === 'noCta' && css.hideCta,
            )}
          >
            Can&apos;t find what you&apos;re looking for? <br />
            <span>
              <Link href="/contact">Contact us</Link> here!
            </span>
          </div>
        ) : (
          <div className={css.citationTypeList} ref={citationTypeList}>
            <ul className={css.citationTypeUl}>
              <>
                {citationTypesToUse.map((citationType: string) => {
                  return (
                    <CitationButton
                      key={citationType}
                      handleClickAdd={handleCitationTypeSelect}
                      citationForm={
                        citationForms[citationType as CitationTypes]
                      }
                      type={citationType as CitationTypes}
                      size="small"
                    />
                  );
                })}
              </>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CitationTypePickerDropDown;
