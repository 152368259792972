import { useDragLayer, XYCoord } from 'react-dnd';
import CitationOutputCitation, {
  CitationOutputCitationProps,
} from 'components/app/citation-output-citation';
import { CSSProperties } from 'react';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  height: '100%',
};

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

const CitationDragLayer = (props: { width?: number }): JSX.Element | null => {
  const { item, isDragging, initialOffset, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem() as CitationOutputCitationProps,
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }),
  );
  if (!isDragging) {
    return null;
  }
  return (
    <div style={{ ...layerStyles, width: `${props.width}px` }}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <CitationOutputCitation {...item} />
      </div>
    </div>
  );
};

export default CitationDragLayer;
