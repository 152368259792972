import React, { useState } from 'react';
import CitationTypePickerDropDown from './citation-type-picker-dropdown';
import css from './citation-type-picker-search-container.module.css';

const CitationTypePickerSearchContainer = () => {
  const [expanded, setExpanded] = useState(true);
  const [pickingCitationType, setPickingCitationType] = useState(true);

  const handleTypeMenu = (): void => {
    setExpanded(!expanded);
    setPickingCitationType(!pickingCitationType);
  };

  return (
    <span className={css.citationTypeSearchContainer}>
      {pickingCitationType && (
        <CitationTypePickerDropDown onClose={handleTypeMenu} />
      )}
    </span>
  );
};

export default CitationTypePickerSearchContainer;
