import React, { useEffect } from 'react';
import citationForms from 'types/citation-forms';
import InputText from './form-inputs/input-text';
import InputDate, { DateValue } from './form-inputs/input-date';
import * as CitationActions from 'redux/modules/citation-module';
import * as CitationListModel from 'models/citation-list-model';
import CitationTypes from 'types/citation-types';
import Button from 'components/common/button';
import { useSelector, useStore } from 'react-redux';
import { Store, useAppDispatch } from 'redux/store';
import { getCurrentCitation } from 'redux/selectors/citation-selector';
import { LabelMedium, LabelSmall } from 'components/common/typography';
import { CitationFieldKey, Name } from 'types/fields';
import cx from 'classnames';
import Tracking from 'lib/tracking';
import usePrevious from 'lib/hooks/usePrevious';
import CitationFocusedField from 'types/citation-focused-field';
import { Citation } from 'redux/modules/citation-module';
import InputNamesContainer from './form-inputs/input-names-container';
import FormLabel from './form-inputs/form-label';
import InformationModal from './information-modal';
import InputPageRange from './form-inputs/input-page-range';
import InputYear from './form-inputs/input-year';
import InputTextArea from './form-inputs/input-text-area';
import yn from 'yn';
import { specialCase } from 'lib/specialCase';
import screenWidthIsGdocsExtension from 'lib/screen-width-is-gdocs-extension';
import css from './citation-input-form.module.css';
import { UNDO_TIMEOUT } from './citation-delete-icon';

interface CitationInputFormProps {
  showInfo: boolean;
  citationInputType: CitationTypes;
  handleChangeCitationInputType: (citationInputType: CitationTypes) => void;
}

let lastFocusedFieldTimeStamp = new Date().getTime();

type ClassConfig = { [key: string]: string[] };

const HALF_LEFT: string[] = [css.halfWidth, css.left];
const HALF_RIGHT: string[] = [css.halfWidth, css.right];
const TWO_THIRDS_LEFT: string[] = [css.twoThirdsWidth, css.left];
const THIRD_RIGHT: string[] = [css.thirdWidth, css.right];

const EMPTY: string[] = [];

const commonConfigs: ClassConfig = {
  edition: HALF_LEFT,
  volume: HALF_RIGHT,
  publisher: TWO_THIRDS_LEFT,
  'publisher-place': THIRD_RIGHT,
  issue: HALF_RIGHT,
};

const customClassNames: { [key: string]: ClassConfig } = {
  defaults: commonConfigs,
  'article-journal': {
    volume: HALF_LEFT,
  },
  'paper-conference': {
    volume: HALF_LEFT,
  },
  review: {
    volume: HALF_LEFT,
  },
  speech: {
    event: TWO_THIRDS_LEFT,
  },
  thesis: {
    publisher: EMPTY,
  },
  broadcast: {
    publisher: EMPTY,
  },
  webpage: {
    publisher: EMPTY,
  },
  legal_case: {
    volume: EMPTY,
  },
  legislation: {
    volume: HALF_LEFT,
  },
  patent: {
    issue: EMPTY,
  },
};

// Determines if citation-input-form should focus a field, ensuring the field isn't
// refocused between citation switches and field focus isn't lost from unrelated rerenders,
// such as typing in fields.
const updateFocusedField = (
  currentCitation: Citation,
  previousCitation?: Citation,
  focusedField?: CitationFocusedField,
): CitationFocusedField | undefined => {
  if (focusedField && lastFocusedFieldTimeStamp < focusedField.timeStamp) {
    lastFocusedFieldTimeStamp = focusedField.timeStamp;
  } else if (
    previousCitation !== currentCitation &&
    previousCitation &&
    currentCitation &&
    previousCitation.data.id === currentCitation.data.id
  ) {
    return undefined;
  }
  return focusedField;
};

const CitationInputForm: React.FC<CitationInputFormProps> = (
  props: CitationInputFormProps,
): JSX.Element => {
  const dispatch = useAppDispatch();
  const isManual = useSelector((state: Store) => state.citation.isManual);
  const editState = useSelector((state: Store) => state.citation.editState);
  const currentCitationForm = citationForms[props.citationInputType];
  const currentCitation = useSelector((state: Store) =>
    getCurrentCitation(state),
  );
  const currentCitationId = currentCitation.data.id;

  let focusedField = useSelector(
    (state: Store) => state.citation.focusedCitationField,
  );
  const previousCitation = usePrevious(currentCitation);
  focusedField = updateFocusedField(
    currentCitation,
    previousCitation,
    focusedField,
  );
  useEffect(() => {
    scrollTo({ top: 0 });
  }, []);
  const store = useStore();
  const citationDoneText = 'Done';

  const handleChange = (field: string, value: unknown): void => {
    dispatch(CitationActions.editCurrentCitation({ [field]: value }));
  };

  const currentCitationFormFieldKeys = Object.keys(currentCitationForm.fields);
  const currentCitationFormNameFieldKeys = currentCitationFormFieldKeys.filter(
    (field) => currentCitationForm.fields[field].type === 'name',
  ) as CitationFieldKey[];
  const duplicatePublisherWebsiteName =
    currentCitation.data.publisher &&
    currentCitationFormFieldKeys.includes('container-title')
      ? currentCitation.data.publisher ===
        currentCitation.data['container-title']
      : undefined;
  const currentCitationNonFormNameFieldKeys =
    currentCitationFormFieldKeys.filter(
      (field) => currentCitationForm.fields[field].type !== 'name',
    ) as CitationFieldKey[];
  return (
    <div className={css.citationInputForm}>
      <div className={css.citationInputFormHeaderContainer}>
        <div className={css.citationInputFormHeader}>
          <div className={css.citationInputFormSelectContainer}>
            <LabelMedium style={{ marginRight: 10 }}>
              {isManual ? 'Add' : 'Edit'}
            </LabelMedium>
            <select
              name="type"
              value={props.citationInputType}
              onChange={(ev) =>
                props.handleChangeCitationInputType(
                  ev.target.value as CitationTypes,
                )
              }
            >
              {Object.keys(citationForms).map((citationType) => {
                return (
                  <option key={citationType} value={citationType}>
                    {citationForms[citationType as CitationTypes].name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className={css.citationInputFormButtonContainer}>
            <div className={css.deleteContainer}>
              <Button
                size="medium"
                type="danger"
                onClick={() => {
                  dispatch(CitationActions.deleteCurrentCitation());
                  dispatch(CitationActions.addToUndoList(currentCitation));
                  setTimeout(() => {
                    dispatch(
                      CitationActions.removeFromUndoList(currentCitationId),
                    );
                  }, UNDO_TIMEOUT);

                  Tracking.trackEvent('Deleted a citation', {
                    buttonLocation: 'edit pane',
                  });
                  CitationListModel.saveCurrentList(store);
                }}
                style={{ marginLeft: 24 }}
              >
                Delete
              </Button>
            </div>
            <Button
              size="medium"
              onClick={async () => {
                dispatch(CitationActions.finishEditingCitation());
                Tracking.trackEvent('Finished editing a citation', {
                  citationType: currentCitation.data.type,
                });
                if (screenWidthIsGdocsExtension()) {
                  setTimeout(
                    () =>
                      document
                        .getElementById('last-made-citation')
                        ?.scrollIntoView({ block: 'center' }),
                    0,
                  );
                }
                await CitationListModel.saveCurrentList(store); // await needed
              }}
              style={{ marginLeft: 24 }}
            >
              {editState === CitationActions.EditStates.NONE
                ? 'Add'
                : yn(process.env.GDOCS)
                ? 'Update'
                : citationDoneText}
            </Button>
          </div>
        </div>
      </div>
      <div className={css.citationInputFormContent}>
        {props.showInfo && (
          <div
            style={{
              fontSize: 14,
              lineHeight: '18px',
              padding: '16px 20px',
              marginBottom: '20px',
              background: 'rgba(202, 157, 0, 0.15)',
              borderRadius: 8,
            }}
          >
            Fill in any missing required info if available, then press
            {' ' + citationDoneText}.
          </div>
        )}
        <div>
          <div style={{ display: 'flex' }}>
            <LabelSmall>Contributors</LabelSmall>
            {(
              currentCitationForm.fields[currentCitationFormNameFieldKeys[0]] ||
              {}
            ).modalInformation && (
              <InformationModal
                modalInformation={
                  (
                    currentCitationForm.fields[
                      currentCitationFormNameFieldKeys[0]
                    ] || {}
                  ).modalInformation
                }
              />
            )}
          </div>
          <InputNamesContainer
            citationId={currentCitation.data.id}
            fields={currentCitationForm.fields}
            keys={currentCitationFormNameFieldKeys}
            values={currentCitation.data}
            focusedField={focusedField}
            onChange={(
              citationFieldKey: CitationFieldKey,
              newValue: Name[] | undefined,
            ) => {
              handleChange(citationFieldKey, newValue);
            }}
          />
        </div>
        {currentCitationForm &&
          currentCitationNonFormNameFieldKeys.map((key) => {
            const citationFieldKey = key as CitationFieldKey;
            const fieldValue = currentCitation.data[citationFieldKey];
            const field = currentCitationForm.fields[citationFieldKey];
            if (!field) return;

            const isFocused =
              focusedField && citationFieldKey === focusedField.key;
            const customClasses =
              customClassNames[currentCitationForm.type] &&
              customClassNames[currentCitationForm.type][citationFieldKey]
                ? customClassNames[currentCitationForm.type][citationFieldKey]
                : customClassNames.defaults[citationFieldKey];

            switch (field.type) {
              case 'date': {
                return (
                  <div key={citationFieldKey} className={cx(customClasses)}>
                    <div className={css.labelWithModal}>
                      <LabelSmall>{field.label}</LabelSmall>
                      {field.modalInformation && (
                        <InformationModal
                          modalInformation={field.modalInformation}
                        />
                      )}
                    </div>

                    <InputDate
                      citationId={currentCitation.data.id}
                      name={citationFieldKey}
                      value={fieldValue as DateValue}
                      isRequired={field.isRequired}
                      isPrimary={field.isRequired}
                      isFocused={isFocused}
                      onChange={(newValue: DateValue) =>
                        handleChange(citationFieldKey, newValue)
                      }
                    />
                  </div>
                );
              }
              case 'text': {
                return (
                  <div key={citationFieldKey} className={cx(customClasses)}>
                    <div className={css.labelWithModal}>
                      <FormLabel htmlFor={citationFieldKey}>
                        {field.label}
                      </FormLabel>
                      {field.modalInformation && (
                        <InformationModal
                          modalInformation={field.modalInformation}
                        />
                      )}
                    </div>
                    <InputText
                      name={citationFieldKey}
                      value={fieldValue?.toString() || ''}
                      isRequired={field.isRequired}
                      isPrimary={field.isRequired}
                      isFocused={isFocused}
                      hasDuplicateWebsitePublisherName={
                        field.label === 'Publisher' &&
                        duplicatePublisherWebsiteName
                      }
                      textCase={field.textCase}
                      specialCase={specialCase.PUBLISHER}
                      onChange={(newValue: string) =>
                        handleChange(citationFieldKey, newValue)
                      }
                    />
                  </div>
                );
              }
              case 'page-range': {
                return (
                  <div key={citationFieldKey} className={cx(customClasses)}>
                    <InputPageRange
                      citationId={currentCitation.data.id}
                      name={citationFieldKey}
                      value={(fieldValue as string) || ''}
                      isRequired={field.isRequired}
                      isPrimary={field.isRequired}
                      isFocused={isFocused}
                      onChange={(newValue: string) =>
                        handleChange(citationFieldKey, newValue)
                      }
                    />
                  </div>
                );
              }
              case 'year': {
                return (
                  <div key={citationFieldKey} className={cx(customClasses)}>
                    <div className={css.labelWithModal}>
                      <LabelSmall>{field.label}</LabelSmall>
                      {field.modalInformation && (
                        <InformationModal
                          modalInformation={field.modalInformation}
                        />
                      )}
                    </div>
                    <InputYear
                      citationId={currentCitation.data.id}
                      name={citationFieldKey}
                      value={fieldValue as DateValue}
                      isRequired={field.isRequired}
                      isPrimary={field.isRequired}
                      isFocused={isFocused}
                      onChange={(newValue: DateValue) =>
                        handleChange(citationFieldKey, newValue)
                      }
                    />
                  </div>
                );
              }
              case 'textarea': {
                return (
                  <div key={citationFieldKey} className={cx(customClasses)}>
                    <div className={css.labelWithModal}>
                      <LabelSmall>{field.label}</LabelSmall>
                      {field.modalInformation && (
                        <InformationModal
                          modalInformation={field.modalInformation}
                        />
                      )}
                    </div>
                    <InputTextArea
                      name={citationFieldKey}
                      value={fieldValue?.toString() || ''}
                      isRequired={field.isRequired}
                      onChange={(newValue: string) =>
                        handleChange(citationFieldKey, newValue)
                      }
                    />
                  </div>
                );
              }
              default: {
                console.log('error!');
              }
            }
          })}
      </div>
    </div>
  );
};

export default CitationInputForm;
