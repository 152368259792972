import React, { useEffect, useRef } from 'react';
import getCitations, {
  CitationDataArray,
  getInTextCitations,
  isCiteprocInitialized,
  setCSLCitationStyle,
} from 'lib/get-citations';

import _throttle from 'lodash/throttle';
import useRerenderIfIdle from 'lib/hooks/useRerenderIfIdle';
import * as CitationActions from 'redux/modules/citation-module';

import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'redux/store';
import yn from 'yn';
import { Citation } from 'redux/modules/citation-module';
import PulseLoader from 'components/common/pulse-loader';
import DraggableCitationOutput from './draggable-citation-output';
import CitationDragLayer from 'components/app/citation-drag-layer';
import isInViewport from 'lib/is-in-viewport';
import cx from 'classnames';
import css from './citation-output-bibliography.module.css';

const THROTTLE_DELAY_PER_CITATION = 20;
const DEFAULT_THROTTLE_DELAY = 0;
const RERENDER_ON_IDLE_MIN_DELAY = 250;

let currentDelay = DEFAULT_THROTTLE_DELAY;

const getIdleRerenderDelayFromThrottleDelay = (): number => {
  return currentDelay + RERENDER_ON_IDLE_MIN_DELAY;
};

const makeThrottleGetCitations = (delay: number): any => {
  currentDelay = delay;
  return _throttle(
    (citations: CitationDataArray) => getCitations(citations),
    delay,
    {
      leading: true,
      trailing: true,
    },
  );
};

let throttledGetCitations = makeThrottleGetCitations(DEFAULT_THROTTLE_DELAY);

const updateThrottleFunction = (numCitations: number): void => {
  const newDelay = numCitations * THROTTLE_DELAY_PER_CITATION;
  if (currentDelay !== newDelay) {
    throttledGetCitations = makeThrottleGetCitations(newDelay);
    currentDelay = newDelay;
  }
};

interface CitationOutputBibliographyProps {
  citations: Citation[];
  citationStyle: { name: string; xml: string };
}

const CitationOutputBibliography = ({
  citations,
  citationStyle,
}: CitationOutputBibliographyProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const editState = useSelector((state: Store) => state.citation.editState);
  const isManual = useSelector((state: Store) => state.citation.isManual);
  const loadingBibliography = useSelector(
    (state: Store) => state.citation.loadingBibliography,
  );
  const citationsDeleting = useSelector(
    (state: Store) => state.citation.deleting,
  );

  const citationInputIndex = useSelector(
    (state: Store) => state.citation.citationInputIndex,
  );
  const dispatch = useAppDispatch();

  const isLoadingBibliography =
    citations.length > 0 &&
    (loadingBibliography || typeof window === 'undefined');

  const hiddenIds = citations
    .filter((citation) => {
      return Object.keys(citation.data).length <= 2;
    })
    .map((citation) => citation.data.id);

  updateThrottleFunction(citations.length);

  let fetchedBibData;
  if (!loadingBibliography) {
    fetchedBibData = throttledGetCitations(citations); // this throttle stuff will make simple rerenders happen 4 times (annoying), but speed up faster editing. maybe we can optimize better
  }
  useRerenderIfIdle(getIdleRerenderDelayFromThrottleDelay(), [
    citations,
    citationStyle,
  ]);
  setTimeout(() => {
    if (typeof window !== 'undefined') {
      const selectedCitationElement =
        document.getElementById('selected-citation');

      if (selectedCitationElement && !isInViewport(selectedCitationElement)) {
        selectedCitationElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, 1);

  useEffect(() => {
    if (loadingBibliography) {
      setCSLCitationStyle(citationStyle);
      dispatch(CitationActions.setLoadingBibliographyFalse());
    }
  }, [citationStyle, dispatch, loadingBibliography]);

  if (citations.length === 0) {
    return (
      <>
        <p className={cx(css.viewCitationsEmpty, 'paragraph-small')}>
          No citations yet
        </p>
      </>
    );
  }

  if (isLoadingBibliography || !fetchedBibData) {
    return (
      <div
        style={{
          margin: 'auto',
        }}
      >
        <PulseLoader />
      </div>
    );
  }

  if (!isCiteprocInitialized() && typeof window !== 'undefined') {
    // do not run this on the backend
    setCSLCitationStyle(citationStyle); // TODO: maybe remove this. might not be needed
  }

  const bibliography = fetchedBibData.bibliography;

  let entryIds: string[][] = [];
  let citationStrings = [];
  if (bibliography) {
    entryIds = bibliography[0].entry_ids;
    citationStrings = bibliography[1];
  }
  const { entryspacing, hangingindent, linespacing } = bibliography[0];
  const flattenedEntryIds = entryIds.flat();
  const sortedCitations = [...citations].sort(function (a, b) {
    return (
      flattenedEntryIds.indexOf(a.data.id) -
      flattenedEntryIds.indexOf(b.data.id)
    );
  });

  if (!citationStrings) {
    // styles like Bluebook Law Review use this
    const formattedCitationIDs: string[] = bibliography
      ? bibliography[0].entry_ids.map((idList: string[]) => idList[0])
      : entryIds;
    citationStrings = getInTextCitations(formattedCitationIDs);
  }
  return (
    <div className={css.currentList} ref={ref}>
      <CitationDragLayer width={ref?.current?.offsetWidth} />
      {citationStrings.map((citationString: string, index: number) => {
        const selected =
          citations[citationInputIndex]?.data.id === entryIds[index][0];
        return (
          <DraggableCitationOutput
            key={sortedCitations[index].data.id}
            editState={editState}
            entryspacing={entryspacing}
            hangingindent={hangingindent}
            linespacing={linespacing}
            isManual={isManual}
            hiddenIds={hiddenIds}
            index={index}
            citation={sortedCitations[index]}
            selected={selected}
            citationString={citationString}
            citationsDeleting={citationsDeleting}
          />
        );
      })}
    </div>
  );
};

export default CitationOutputBibliography;
