import React from 'react';

import { AxiosResponse } from 'axios';
import { errorCodes, UrlReqError } from 'types/url-req-error';
import { fillManuallyReqErrors } from './fill-manually-req-errors';
import { chromeExtensionUrl } from 'components/common/search-error';
import reportException from './sentry';

export const getUrlReqErrorFromResStatus = (
  response: AxiosResponse,
  request: XMLHttpRequest,
): UrlReqError | undefined => {
  if (!response) return undefined;
  if (response.status === 400) {
    if (
      request && // check if 400 from scraped website or from our server due to non-existent address
      request.responseText &&
      request.responseText.includes('Address not found')
    ) {
      return UrlReqError.InvalidURL; // came from our server
    } else {
      return UrlReqError.Malformed; // came from external site
    }
  } else if (
    response.status === 403 ||
    response.status === 302 ||
    response.status === 999 ||
    response.status === 502
  ) {
    return UrlReqError.Forbidden;
  } else if (response.status === 404) {
    return UrlReqError.NotFound;
  } else if (response.status === 503) {
    return UrlReqError.Timeout;
  } else if (response.status === 555) {
    return UrlReqError.PdfParsingError;
  } else if (response.status >= 400) {
    return UrlReqError.External;
  }
};

export const LoginPageError = () => (
  <span style={{ lineHeight: 1.5 }}>
    We have detected that this is a URL that requires you to be logged in.
    <br />
    <br />
    If you want to cite this correctly, you need the{' '}
    <a href={chromeExtensionUrl} target="_blank" rel="noreferrer noopener">
      Bibcitation Chrome Extension
    </a>{' '}
    as we cannot access it otherwise.
  </span>
);

export const urlReqValidator = (
  input?: string,
  reqError?: UrlReqError,
  skipUrlValidation?: boolean,
): string | JSX.Element | undefined => {
  if (input !== undefined && !skipUrlValidation) {
    if (input === '') {
      return; // don't show error message if nothing entered
    }
    if (input.startsWith('10.')) {
      return 'This looks like a DOI. Please enter this in journal article search.';
    }
    if (!input.includes('.') && input.length > 0) {
      return 'Please enter a valid URL.';
    }
    if (input.trim().includes(' ')) {
      return "Please enter a valid URL. URLs can't have spaces in them.";
    }
    const isChrome =
      navigator.userAgent.includes('Chrome') &&
      navigator.vendor.includes('Google Inc');
    if (input.includes('.proxy') || (input.includes('proxy.') && isChrome)) {
      reportException(`Showed proxy error for ${input}`);
      return <LoginPageError />;
    }
  }
  if (reqError === undefined) return;
  if (errorCodes[reqError]) {
    return errorCodes[reqError];
  }
};

// Intended to be called before executing a request to the url using
// useReqLimiter(). Intended to prioritize validation of url first,
// and then check errors returned from the last request.
export const processInputAndUrlReqError = (
  url: string | undefined,
  reqError: UrlReqError | undefined,
  skipUrlValidation?: boolean,
): {
  errorMsg: string | JSX.Element;
  errorFillManually: boolean;
  invalidInput: boolean;
} => {
  let errorMsg: string | JSX.Element = '';
  let errorFillManually = false;
  let invalidInput = !url;
  if (url || reqError) {
    const possibleErr = urlReqValidator(url, reqError, skipUrlValidation);
    if (possibleErr) {
      errorFillManually =
        reqError !== undefined && fillManuallyReqErrors.has(reqError);
      invalidInput = true;
      errorMsg = possibleErr;
    }
  }
  return { errorMsg, errorFillManually, invalidInput };
};
