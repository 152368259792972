import React, { useState } from 'react';
import CitationStylePicker from './citation-style-picker';
import { getShortStyleTitle } from 'lib/get-style-title';
import { colors } from 'css/variables';
import PulseLoader from 'components/common/pulse-loader';
import css from './citation-output-style.module.css';

const DownArrow: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
): JSX.Element => (
  <svg width="11" height="7" viewBox="0 0 11 7" fill="none" {...props}>
    <path
      d="M1 1L5.5 5L10 1"
      stroke={colors.purple.medium}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

interface CitationOutputStyleProps {
  citationStyle: { name: string; xml: string };
  onChangeCitationStyle: (citationStyleKey: string) => void;
  loadingCitationStyle: boolean;
}

const CitationOutputStyle: React.FC<CitationOutputStyleProps> = (
  props: CitationOutputStyleProps,
) => {
  const [expanded, setExpanded] = useState(false);
  const [pickingCitationStyle, setPickingCitationStyle] = useState(false);
  const handleChangeCitationStyle = (citationStyleKey: string): void => {
    props.onChangeCitationStyle(citationStyleKey);
    setPickingCitationStyle(false);
  };
  const openCitationMenu = (): void => {
    setExpanded(true);
    setPickingCitationStyle(true);
  };
  const closeCitationMenu = (): void => {
    setExpanded(false);
    setPickingCitationStyle(false);
  };

  return (
    <span>
      <button
        aria-label={
          `${props.citationStyle.name}` + ' selected, citation style menu'
        }
        aria-expanded={expanded}
        className={css.citationStyle}
        onClick={() => openCitationMenu()}
      >
        {props.loadingCitationStyle ? (
          <PulseLoader />
        ) : (
          getShortStyleTitle(props.citationStyle.name)
        )}
        <span className={css.downArrowContainer}>
          <DownArrow />
        </span>
      </button>
      {pickingCitationStyle && (
        <CitationStylePicker
          citationStyle={props.citationStyle}
          onChangeCitationStyle={handleChangeCitationStyle}
          onClose={() => closeCitationMenu()}
        />
      )}
    </span>
  );
};

export default CitationOutputStyle;
