const isCapitalOrNumber = (char: string): boolean => {
  return char === char.toUpperCase() || !isNaN(parseInt(char, 10));
};

const isCapitalOrNumberAndNotWhitespace = (char: string): boolean => {
  return isCapitalOrNumber(char) && char !== ' ';
};

const isRISKey = (line: string) => {
  return (
    line.length === 2 &&
    isCapitalOrNumberAndNotWhitespace(line[0]) &&
    isCapitalOrNumberAndNotWhitespace(line[1])
  );
};

const isRISLine = (line: string) => {
  return line.length > 2 && isRISKey(line.slice(0, 2)) && line[2] === ' ';
};
const cleanupRIS = (text: string) => {
  const newLines: string[] = [];
  const lines: string[] = text.split('\n');
  lines.forEach((line) => {
    if (isRISLine(line)) {
      newLines.push(line);
    } else {
      newLines[newLines.length - 1] += line;
    }
  });
  return newLines.join('\n');
};

export default cleanupRIS;
