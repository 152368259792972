enum CitationTypesText { // same keys as citation types, ui-friendly values
  'artwork' = 'artwork',
  'blogPost' = 'blog post',
  'book' = 'book',
  'bookReview' = 'book review',
  'bookChapter' = 'book chapter',
  'conferencePaper' = 'conference paper',
  'database' = 'database',
  'dictionaryEntry' = 'dictionary entry',
  'document' = 'document',
  'ebook' = 'ebook',
  'encyclopediaEntry' = 'encyclopedia entry',
  'filmOrMovie' = 'film or movie',
  'image' = 'image',
  'interview' = 'interview',
  'journalArticle' = 'journal article',
  'legalCase' = 'legal case',
  'legalBill' = 'legal bill',
  'legislation' = 'legislation',
  'magazineArticle' = 'magazine article',
  'manuscript' = 'manuscript',
  'map' = 'map',
  'newsArticle' = 'news article',
  'patent' = 'patent',
  'personalCommunication' = 'personal communication',
  'report' = 'report',
  'review' = 'review',
  'song' = 'song',
  'speech' = 'speech',
  'thesis' = 'thesis',
  'tvRadioBroadcast' = 'tv radio broadcast',
  'video' = 'video',
  'website' = 'website',
}

export default CitationTypesText;
