import React, { useEffect, useState, useRef } from 'react';
import {
  getCitationStyles,
  getPopularCitationStyles,
} from 'redux/modules/citation-module';
import { getLongStyleTitle } from 'lib/get-style-title';
import cx from 'classnames';
import CitationStylePickerSearch from './citation-style-picker-search';
import {
  LabelSmall,
  ParagraphMedium,
  ParagraphSmall,
} from 'components/common/typography';
import { useAppDispatch, Store } from 'redux/store';
import { useSelector } from 'react-redux';
import { colors } from 'css/variables';
import popularStyles from 'types/popular-styles';
import useClickOutsideDetector from 'lib/hooks/useClickOutsideDetector';
import css from './citation-style-picker.module.css';

const Close: React.FC<React.SVGProps<SVGSVGElement>> = (props): JSX.Element => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx={8} cy={8} r={8} fill={colors.purple.medium} fillOpacity={0.1} />
    <path
      d="M5 5L11 11"
      stroke={colors.purple.medium}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M11 5L5 11"
      stroke={colors.purple.medium}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

interface CitationStylePickerProps {
  citationStyle: { name: string; xml: string };
  onChangeCitationStyle: (citationStyleKey: string) => void;
  onClose: () => void;
}

interface CitationStyle {
  title: string;
  titleShort?: string | undefined;
  priority: number;
}

const CitationStylePicker: React.FC<CitationStylePickerProps> = (
  props: CitationStylePickerProps,
) => {
  const pickerRef = useRef(null);
  useClickOutsideDetector(pickerRef, props.onClose);
  const [searchTerm, setSearchTerm] = useState('');
  const citationStyleList = useRef<HTMLDivElement | null>(null);
  const loadingCitationStyles = useSelector(
    (state: Store) => state.citation.loadingCitationStyles,
  );
  const citationStyles = useSelector(
    (state: Store) => state.citation.citationStyles,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleGetCitationStyles = async (): Promise<void> => {
      if (citationStyles.length === 0) {
        await dispatch(getPopularCitationStyles());
        dispatch(getCitationStyles());
      }
    };
    handleGetCitationStyles();
  }, [citationStyles.length, dispatch]);

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  function findCitationStyles(
    citationStyles: CitationStyle[],
    searchTerm: string,
  ): CitationStyle[] {
    const splitSearchTerm: string[] = searchTerm.split(' ');
    const filteredCitations = citationStyles.filter((citationStyle) => {
      return splitSearchTerm.every(
        (term) =>
          getLongStyleTitle(citationStyle.title).toLowerCase().includes(term) ||
          citationStyle.titleShort?.toLowerCase().includes(term),
      );
    });
    return filteredCitations;
  }

  const citationStylesToUse = searchTerm
    ? findCitationStyles(citationStyles, lowerCaseSearchTerm)
    : citationStyles;

  return (
    <div ref={pickerRef} className={css.citationStylePicker}>
      <div className={css.citationStylePickerInner}>
        <div className={css.citationStylePickerHeader}>
          <button
            onClick={props.onClose}
            className={css.citationStylePickerCloseButton}
            aria-label="Close menu"
          >
            <Close />
          </button>

          <LabelSmall>Citation style</LabelSmall>
          <CitationStylePickerSearch
            searchTerm={searchTerm}
            onClose={props.onClose}
            onChangeSearchTerm={(searchTerm) => {
              if (citationStyleList.current) {
                citationStyleList.current.scrollTop = 0;
              }
              setSearchTerm(searchTerm);
            }}
          />
        </div>
        <div className={css.citationStyleList} ref={citationStyleList}>
          {loadingCitationStyles ? (
            <div className={css.citationStyleListLoading}>Loading...</div>
          ) : (
            citationStylesToUse.map((citationStyle) => {
              const isCurrent =
                props.citationStyle.name === citationStyle.title;
              return (
                <button
                  key={citationStyle.title}
                  className={cx(
                    css.citationStyleListItem,
                    isCurrent && css.citationStyleListItemCurrent,
                  )}
                  onClick={() =>
                    props.onChangeCitationStyle(citationStyle.title)
                  }
                >
                  <ParagraphMedium style={{ textAlign: 'left' }}>
                    {getLongStyleTitle(citationStyle.title)}
                  </ParagraphMedium>
                  <span className={css.status}>
                    {isCurrent ? (
                      <ParagraphSmall color={colors.purple.medium}>
                        current
                      </ParagraphSmall>
                    ) : citationStyle.priority !== popularStyles.length ? (
                      <ParagraphSmall color={colors.yellow.medium}>
                        popular
                      </ParagraphSmall>
                    ) : (
                      ''
                    )}
                  </span>
                </button>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default CitationStylePicker;
