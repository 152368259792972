import * as React from 'react';
import { ParagraphMedium } from './typography';
import css from './hero.module.css';

export const Hero: React.FC = (): JSX.Element => {
  return (
    <h1 className={css.hero}>
      <ParagraphMedium>
        Bibcitation is a free citation generator that creates bibliographies,
        references and works cited. Automatically generate MLA, APA, Chicago and
        other citations and create an accurate bibliography in one click.
      </ParagraphMedium>
    </h1>
  );
  0;
};

export default Hero;
