import { AxiosResponse } from 'axios';
import extractCitation from 'lib/extract-citation';
import useReqLimiter from 'lib/hooks/useReqLimiter';
import {
  searchJournalMetadataGeneral,
  RESULTS_PER_PAGE_BOOK,
} from 'models/citation-model';
import React, { useState } from 'react';
import CitationTypes from 'types/citation-types';
import JournalSearchSubtypeProps from 'types/journal-search-subtype-props';
import { JournalResponseItem, JournalResponse } from 'types/network-responses';
import CitationInputSearch from './citation-input-search';
import CitationInputSearchJournalResult from './citation-input-search-journal-result';
import reportException from 'lib/sentry';
import { unescape } from 'lib/util';
interface CitationInputSearchJournalQueryGeneralProps
  extends JournalSearchSubtypeProps {
  pageNum: number;
}

const CitationInputSearchJournalQueryGeneral: React.FC<
  CitationInputSearchJournalQueryGeneralProps
> = (props): JSX.Element => {
  const { inputVal, pageNum } = props;
  const emptyResponse = { data: { message: { items: [] } } };
  type PossibleRes = typeof emptyResponse | AxiosResponse<JournalResponse>;

  const [endOfResults, setEndOfResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<JournalResponse>(emptyResponse.data);

  const onClickCitation = (index: number): void => {
    if (response) {
      const citationData = extractCitation(
        'crossref',
        response.message.items[index],
      );
      props.handleClickCitation(citationData);
    }
  };

  const onChange = (value: string, page: number): void => {
    props.handleChange(value, page);
  };

  const alwaysRunBeforeCallback = (): void => {
    setLoading(true);
  };
  const reqCallback = async (
    value: string,
    page: number,
  ): Promise<PossibleRes> => {
    return value === ''
      ? emptyResponse
      : await searchJournalMetadataGeneral(value, page, props.citationType);
  };

  const handleDecodingTitle = (
    data: JournalResponseItem[],
  ): JournalResponseItem[] => {
    return data.map((item: JournalResponseItem) => {
      if (item.title?.length > 0) {
        return { ...item, title: [unescape(item.title[0])] };
      } else {
        return item;
      }
    });
  };

  const resCallback = (
    newResponse: PossibleRes,
    _value: string,
    page: number,
    oldResponseData: JournalResponse,
  ): void => {
    if (newResponse.data.message) {
      const resultsLength = newResponse.data.message.items.length;
      setEndOfResults(resultsLength < RESULTS_PER_PAGE_BOOK);
    }
    const decodedItems = handleDecodingTitle(newResponse.data.message.items);
    newResponse.data.message.items = decodedItems;

    if (page === 0) {
      setLoading(false);
      setResponse(newResponse.data);
    } else if (oldResponseData) {
      setLoading(false);
      const newItems = oldResponseData?.message.items.concat(
        newResponse.data.message.items,
      );
      setResponse({
        ...newResponse.data,
        message: {
          ...newResponse.data.message,
          items: newItems,
        },
      });
    } else {
      reportException('Response is undefined when it should not be.');
    }
  };

  const onError = (): void => {
    setLoading(false);
  };

  useReqLimiter(
    alwaysRunBeforeCallback,
    reqCallback,
    resCallback,
    onError,
    { delay: 500, type: 'throttle' },
    [inputVal, pageNum],
    inputVal,
    pageNum,
    response,
  );

  return (
    <CitationInputSearch
      citationType={props.citationType}
      type={CitationTypes.journalArticle}
      minimumSearchLength={2}
      loading={loading}
      items={response?.message.items}
      onClickCitation={onClickCitation}
      placeholder={props.placeholder}
      onChange={onChange}
      renderSearchResult={(item: JournalResponseItem) => (
        <CitationInputSearchJournalResult item={item} />
      )}
      endOfResults={endOfResults}
      existingValue={props.inputVal}
      uploadButton={props.uploadButton}
    />
  );
};

export default CitationInputSearchJournalQueryGeneral;
