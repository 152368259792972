import React, { useState } from 'react';
import CitationInputSearchJournal from './citation-input-search-journal';
import CitationInputSearchBook from './citation-input-search-book';
import CitationInputSearchWebsite from './citation-input-search-website';
import CitationInputForm from './citation-input-form';
import CitationTypes from 'types/citation-types';
import { getCurrentCitation } from 'redux/selectors/citation-selector';
import { useSelector } from 'react-redux';
import { Store } from 'redux/store';
import { EditStates } from 'redux/modules/citation-module';
import cx from 'classnames';
import CitationIntextFooterForm from './citation-intext-footer-form';
import usePrevious from 'lib/hooks/usePrevious';
import css from './citation-input.module.css';

const webSearchArray = [
  CitationTypes.website,
  CitationTypes.video,
  CitationTypes.newsArticle,
  CitationTypes.blogPost,
  CitationTypes.interview,
  CitationTypes.song,
  CitationTypes.encyclopediaEntry,
  CitationTypes.dictionaryEntry,
];

interface CitationInputProps {
  onChangeCitationInputType: (citationInputType: CitationTypes) => void;
  query?: string;
  type?: string;
}

const CitationInput: React.FC<CitationInputProps> = (
  props: CitationInputProps,
): JSX.Element => {
  const [showInfo, setShowInfo] = useState(false);
  const currentCitation = useSelector((state: Store) =>
    getCurrentCitation(state),
  );
  const editState = useSelector((state: Store) => state.citation.editState);
  const isManual = useSelector((state: Store) => state.citation.isManual);
  const inputGlowFormFlag = useSelector(
    (state: Store) => state.citation.inputFormGlowFlag,
  );

  const prevEditState = usePrevious(editState);
  const prevCitationId = usePrevious(currentCitation.data.id);
  const renderCitationInput = (): JSX.Element => {
    if (editState !== EditStates.NONE || isManual) {
      if (editState === EditStates.BIB_ITEM) {
        return (
          <CitationInputForm
            showInfo={showInfo}
            citationInputType={currentCitation.citationType}
            handleChangeCitationInputType={props.onChangeCitationInputType}
          />
        );
      } else if (editState === EditStates.IN_TEXT_FOOT) {
        return <CitationIntextFooterForm />;
      }
    }
    if (
      currentCitation.citationType === CitationTypes.journalArticle ||
      currentCitation.citationType === CitationTypes.conferencePaper ||
      currentCitation.citationType === CitationTypes.report ||
      currentCitation.citationType === CitationTypes.thesis
    ) {
      return (
        <CitationInputSearchJournal
          setShowInfo={setShowInfo}
          citationType={currentCitation.citationType}
        />
      );
    } else if (
      currentCitation.citationType === CitationTypes.book ||
      currentCitation.citationType === CitationTypes.ebook
    ) {
      return (
        <CitationInputSearchBook
          initialValue={props.query}
          setShowInfo={undefined}
          citationType={currentCitation.citationType}
        />
      );
    } else if (currentCitation.citationType === CitationTypes.bookChapter) {
      return (
        <CitationInputSearchBook
          setShowInfo={setShowInfo}
          citationType={currentCitation.citationType}
        />
      );
    } else if (webSearchArray.includes(currentCitation.citationType)) {
      return (
        <CitationInputSearchWebsite
          setShowInfo={setShowInfo}
          citationType={currentCitation.citationType}
        />
      );
    } else {
      return (
        <CitationInputForm
          showInfo={showInfo}
          citationInputType={currentCitation.citationType}
          handleChangeCitationInputType={props.onChangeCitationInputType}
        />
      );
    }
  };
  return (
    <section
      className={cx(
        css.addCitations,
        // should only glow when user interacts with citation and form UI doesn't change
        prevCitationId === currentCitation.data.id &&
          prevEditState === editState &&
          css.glow,
        inputGlowFormFlag ? css.animationDurationOne : css.animationDurationTwo,
      )}
    >
      {renderCitationInput()}
    </section>
  );
};

export default CitationInput;
