export const clarityCode = `
(function(c,l,a,r,i,t,y){
  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "51ujklws92");`;

const enableClarity = (): void => {
  if (!document.querySelector('[key=clarity]')) {
    const scriptClarity = document.createElement('script');
    scriptClarity.innerHTML = clarityCode;
    scriptClarity.setAttribute('key', 'clarity');
    document.body.appendChild(scriptClarity);
  }
};

export default enableClarity;
