import isInViewport from './is-in-viewport';
export default function autoScrollToElementWithId(elementId: string): void {
  setTimeout(() => {
    if (typeof window !== 'undefined') {
      const selectedCitationElement = document.getElementById(elementId);
      if (selectedCitationElement && !isInViewport(selectedCitationElement)) {
        selectedCitationElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, 0);
}
