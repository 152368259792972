import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { useEffect, useRef } from 'react';
import { FuncLimiter } from 'types/func-limiter';

let latestReqTime: number;

// For throttling requests and ensuring stale responses
// are not processed.
// Any extra args passed at the end of the method are fed to each callback.
// Note: be careful with the callback methods:
//        a response is expected to be returned from reqCallback
//        to be used as the first argument of afterReqCallback.
const useReqLimiter = <T, U extends unknown[]>(
  alwaysRunBeforeCallback: (...args: U) => void, // this will always run before reqCallback is called
  reqCallback: (...args: U) => Promise<T>,
  onResCallback: (res: T, ...args: U) => void,
  onError: (err: unknown, ...args: U) => void,
  config: { delay: number; type: FuncLimiter; dontCallReq?: boolean },
  stateDependencies?: React.DependencyList,
  ...args: U
): void => {
  const functionToUse = config.type === 'debounce' ? debounce : throttle;
  const throttledFunc = useRef(
    functionToUse(
      async (curTime: number, ...args: U) => {
        let res: T;
        try {
          res = await reqCallback(...args);
          if (curTime < latestReqTime) {
            return; // response is stale
          }
        } catch (err) {
          if (curTime < latestReqTime) {
            return;
          }
          onError(err, ...args);
          return;
        }

        onResCallback(res, ...args);
      },
      config.delay,
      { trailing: true, leading: true },
    ),
  );

  useEffect(() => {
    if (config.dontCallReq) return;

    const executeReq = async (): Promise<void> => {
      latestReqTime = new Date().getTime();
      await throttledFunc.current(latestReqTime, ...args);
    };

    alwaysRunBeforeCallback(...args);
    executeReq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, stateDependencies);
};

export default useReqLimiter;
