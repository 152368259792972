import React from 'react';
import * as CitationActions from 'redux/modules/citation-module';
import { useAppDispatch } from 'redux/store';
import Tracking from 'lib/tracking';
import { useSelector } from 'react-redux';
import { getCurrentList } from 'redux/selectors/citation-list-selector';
import css from './citation-delete-icon.module.css';
import cx from 'classnames';

export const UNDO_TIMEOUT = 6000;

const TrashIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
): JSX.Element => (
  <svg width={47} height={52} fill="none" {...props}>
    <g filter="url(#trash)">
      <circle cx={26} cy={22} r={10} fill="#fff" />
    </g>
    <path
      d="M22.25 18.25l7.5 7.5m0-7.5l-7.5 7.5"
      stroke="#8C4EF1"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <defs>
      <filter
        id="trash"
        x={0}
        y={0}
        width={52}
        height={52}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={8} />
        <feColorMatrix values="0 0 0 0 0.259535 0 0 0 0 0 0 0 0 0 0.683333 0 0 0 0.08 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={4} />
        <feColorMatrix values="0 0 0 0 0.258824 0 0 0 0 0 0 0 0 0 0.682353 0 0 0 0.08 0" />
        <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feColorMatrix values="0 0 0 0 0.258824 0 0 0 0 0 0 0 0 0 0.682353 0 0 0 0.08 0" />
        <feBlend in2="effect2_dropShadow" result="effect3_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

interface CitationDeleteIconProps {
  citationToDelete: CitationActions.Citation;
  isEmpty: boolean;
  citationTitle?: string;
  height?: string;
  width?: string;
}

const CitationDeleteIcon: React.FC<CitationDeleteIconProps> = (
  props: CitationDeleteIconProps,
): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentList = useSelector(getCurrentList);

  const handleClick = async (): Promise<void> => {
    await dispatch(
      CitationActions.deleteCitation({
        listId: currentList.id,
        citationId: props.citationToDelete.data.id,
      }),
    );

    if (!props.isEmpty) {
      dispatch(CitationActions.addToUndoList(props.citationToDelete));
      setTimeout(() => {
        dispatch(
          CitationActions.removeFromUndoList(props.citationToDelete.data.id),
        );
      }, UNDO_TIMEOUT);
    }

    Tracking.trackEvent('Deleted a citation', {
      buttonLocation: 'citation output',
    });
  };
  return (
    <>
      <button
        aria-label={
          'Delete ' +
          `${props.citationTitle ? props.citationTitle : 'empty'}` +
          ' Citation'
        }
        className={cx('trash-icon', css.trashIcon)}
        onMouseDown={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
          e.stopPropagation();
        }}
        onMouseUp={() => {
          handleClick();
        }}
        onKeyPress={(e: React.KeyboardEvent<HTMLButtonElement>) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            handleClick();
          }
        }}
      >
        <TrashIcon />
      </button>
    </>
  );
};

export default CitationDeleteIcon;
