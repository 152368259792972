import React, { useState, useCallback } from 'react';
import { searchBookMetadata } from 'models/citation-model';
import extractCitation from 'lib/extract-citation';
import CitationTypes from 'types/citation-types';
import CitationInputSearch from './citation-input-search';
import * as CitationListModel from '../../models/citation-list-model';
import * as CitationActions from 'redux/modules/citation-module';
import { Store, useAppDispatch } from 'redux/store';
import throttle from 'lodash/throttle';
import { LabelMedium, LabelSmall } from 'components/common/typography';
import { colors } from 'css/variables';
import { BookResponse, BookResponseItem } from 'types/network-responses';
import LinkSVG from 'static/svgs/icon/12/external link.svg';
import Tracking from 'lib/tracking';
import { RESULTS_PER_PAGE_JOURNAL } from 'scraper/lib/crossref/constants';
import BulletedSpan from './bulleted-span';
import { useSelector, useStore } from 'react-redux';
import {
  capitalizeSubtitles,
  isTitleCase,
  toSentenceCase,
} from 'lib/string-formatting';
import autoScrollToElementWithId from 'lib/auto-scroll-to-element-with-id';
import screenWidthIsGdocsExtension from 'lib/screen-width-is-gdocs-extension';
import css from './citation-input-search-result-single.module.css';

let mostRecentSearch: string | undefined = undefined;

interface IndustryIdentifier {
  type: string;
  identifier: string;
}

const emptyResponse: {
  data: { items: BookResponseItem[]; searchURI: string };
} = {
  data: { items: [], searchURI: '' },
};

interface CitationInputSearchBookProps {
  citationType: CitationTypes;
  setShowInfo: ((showInfo: boolean) => void) | undefined; // undefined if unneccesary
  initialValue?: string;
}

const CitationInputSearchBook: React.FC<CitationInputSearchBookProps> = (
  props: CitationInputSearchBookProps,
): JSX.Element => {
  const [response, setResponse] = useState<BookResponse>(emptyResponse.data);
  const [loading, setLoading] = useState(false);
  const currentCitationStyle = useSelector(
    (state: Store) => state.citation.citationStyle,
  );
  const nlpData = useSelector((state: Store) => state.citation.citationNlpData);
  const [endOfResults, setEndOfResults] = useState(false);
  const store = useStore();
  const dispatch = useAppDispatch();
  const isAPA = currentCitationStyle.name.includes(
    'American Psychological Association',
  );

  const throttledSearchBookMetadata = useCallback(
    (value: string, page: number) => {
      const throttledFunction = throttle(
        async () => {
          const newResponse =
            value === ''
              ? emptyResponse
              : await searchBookMetadata(value, page);
          if (value !== mostRecentSearch) {
            return; // the response is for a stale request
          }
          if (newResponse.data.items) {
            const resultsLength = newResponse.data.items.length;
            setEndOfResults(resultsLength < RESULTS_PER_PAGE_JOURNAL);
          }
          if (page === 0) {
            setLoading(false);
            setResponse(newResponse.data);
          } else if (response) {
            setLoading(false);
            setResponse({
              ...newResponse.data,
              items: response?.items.concat(newResponse.data.items),
            });
          } else {
            console.error('Response is undefined when it should not be.');
          }
          return response;
        },
        500,
        {
          leading: true,
          trailing: true,
        },
      );
      throttledFunction();
    },
    [response],
  );

  const handleChange = async (value: string, page: number): Promise<void> => {
    mostRecentSearch = value;
    try {
      setLoading(true);
      await throttledSearchBookMetadata(value, page);
    } catch (e) {
      console.log('google search error', e);
    }
  };

  const handleClickCitation = (index: number): void => {
    if (
      response &&
      (props.citationType === 'book' || props.citationType === 'ebook')
    ) {
      const citation = extractCitation('google', response.items[index]);
      if (citation.title && isAPA) {
        if (isTitleCase(citation.title)) {
          citation.title = toSentenceCase(
            citation.title,
            nlpData[citation.title],
          );
        } else {
          citation.title = capitalizeSubtitles(citation.title); // less aggressive than the first
        }
      }
      dispatch(CitationActions.editCurrentCitation(citation));
      Tracking.trackEvent('Finished editing a citation', {
        citationType: props.citationType,
      });
      if (!screenWidthIsGdocsExtension()) {
        // breakpoints.small in integer form
        dispatch(CitationActions.fillManually());
      } else {
        dispatch(CitationActions.finishEditingCitation());
      }
    } else if (response) {
      const citation = extractCitation('googleChapter', response.items[index]);
      dispatch(CitationActions.editCurrentCitation(citation));
      if (props.setShowInfo) {
        props.setShowInfo(true);
        dispatch(CitationActions.fillManually());
      }
    }
    autoScrollToElementWithId('last-made-citation');
    CitationListModel.saveCurrentList(store);
  };
  const renderSearchResult = (item: BookResponseItem): JSX.Element => {
    const volumeInfo = item.volumeInfo;
    let title = volumeInfo.title;
    if (volumeInfo.subtitle) {
      title = `${title}: ${volumeInfo.subtitle}`;
    }
    return (
      <div
        key={item.etag}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <div
          style={{ minWidth: 55, maxWidth: 55, marginLeft: 8, marginRight: 16 }}
        >
          {volumeInfo.imageLinks && (
            <img
              src={volumeInfo.imageLinks.smallThumbnail.replace(
                'http',
                'https',
              )}
              alt="book cover"
              style={{ width: '100%' }}
            />
          )}
        </div>
        <div>
          <div className={css.row}>
            <LabelMedium>{title}</LabelMedium>
          </div>
          <div className={css.row}>
            <LabelSmall color={colors.grey.dark}>
              {volumeInfo.authors &&
                volumeInfo.authors.map((author: string, index: number) => {
                  return (
                    <span key={author}>
                      {index !== 0 && <span>, </span>}
                      <span key={index}>{author}</span>
                    </span>
                  );
                })}
            </LabelSmall>
          </div>
          <div className={css.row}>
            <BulletedSpan
              items={[
                volumeInfo.publisher,
                volumeInfo.publishedDate,
                typeof volumeInfo.pageCount === 'number'
                  ? `${volumeInfo.pageCount} pages`
                  : undefined,
              ]}
            />
          </div>
          <div className={css.row}>
            <LabelSmall color={colors.grey.dark}>
              {volumeInfo.industryIdentifiers && (
                <span>
                  ISBN:&nbsp;
                  {volumeInfo.industryIdentifiers.map(
                    (industryIdentifier: IndustryIdentifier, index: number) => {
                      return (
                        <span key={industryIdentifier.identifier}>
                          {index !== 0 && <span>, </span>}
                          {industryIdentifier.identifier}
                        </span>
                      );
                    },
                  )}
                </span>
              )}
            </LabelSmall>
          </div>
          <div className={css.row}>
            <LabelSmall color={colors.grey.dark}>
              <a
                href={volumeInfo.infoLink}
                target="_blank"
                onClick={(ev) => ev.stopPropagation()}
                rel="noopener noreferrer"
              >
                <span className={css.icon}>
                  <LinkSVG />
                </span>
                Show more
              </a>
            </LabelSmall>
          </div>
        </div>
      </div>
    );
  };
  const placeholder = 'Title, ISBN, author, etc...';
  return (
    <CitationInputSearch
      initialValue={props.initialValue}
      citationType={props.citationType}
      type={CitationTypes.book}
      loading={loading}
      items={response.items}
      onClickCitation={handleClickCitation}
      onChange={handleChange}
      placeholder={placeholder}
      renderSearchResult={renderSearchResult}
      endOfResults={endOfResults}
    />
  );
};

export default CitationInputSearchBook;
