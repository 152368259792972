import { MutableRefObject, useEffect, useRef, useState } from 'react';

// idleDelay is time for the user to be idle, before forcing a
// rerender.
// Must have stateDependencies, or there will be INFINITE LOOP:
//    Hooks must be at the top level, so this hook will run
//    again on each rerender.
const useRerenderIfIdle = (
  idleDelay: number,
  stateDependencies: unknown[],
): void => {
  const initialTime = useRef<number>(0);
  const [, setTick] = useState(0); // forces rerender

  initialTime.current = new Date().getTime();

  const rerender = (initialTimeRef: MutableRefObject<number>): void => {
    const curTime = new Date().getTime();

    if (curTime - initialTimeRef.current >= idleDelay) {
      // If there was another call to this hook (a new initialTime set),
      // this block won't run,  ensuring the rerender only happens when idle
      setTick((tick) => tick + 1);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      rerender(initialTime);
    }, idleDelay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, stateDependencies); // we don't want idleDelay or rerender to be part of the dependency array.
};

export default useRerenderIfIdle;
