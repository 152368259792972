import React from 'react';
import CitationTypes from 'types/citation-types';
import { CitationForm } from 'types/citation-forms';
import { LabelMedium } from 'components/common/typography';
import { darken } from 'lib/color';
import css from './citation-button.module.css';
import cx from 'classnames';

interface CitationButtonProps {
  handleClickAdd: (citationTypeKey: CitationTypes) => void;
  type: CitationTypes;
  citationForm: CitationForm;
  size: 'large' | 'small';
}
export const CitationButton = (props: CitationButtonProps): JSX.Element => {
  const dynamicColor: string = darken(props.citationForm.color, 10);
  return (
    <button
      key={props.type}
      className={cx(
        css.addContainer,
        props.size === 'small'
          ? css.addContainerSmall
          : css.addContainerDefault,
      )}
      onClick={(ev) => {
        ev.preventDefault();
        props.handleClickAdd(props.type);
      }}
      style={
        {
          '--dynamic-bg-color': props.citationForm.color,
          '--dynamic-bg-color-darkened': dynamicColor,
        } as React.CSSProperties
      }
    >
      <div className={cx(css.circle, css.circleDesktop)}>
        <props.citationForm.icon
          height={props.size === 'large' ? '16' : '16'}
        />
      </div>
      <div className={cx(css.circle, css.circleMobile)}>
        <props.citationForm.icon height="12" />
      </div>

      <LabelMedium
        color="white"
        style={{ height: 24, display: 'flex', alignItems: 'center' }}
      >
        <span
          className={
            props.size === 'large' ? css.citeTextLarge : css.citeTextSmall
          }
        >
          {props.citationForm.name}
        </span>
      </LabelMedium>
    </button>
  );
};
