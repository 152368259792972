import { LabelMedium, LabelSmall } from 'components/common/typography';
import { colors } from 'css/variables';
import LinkSVG from 'static/svgs/icon/12/external link.svg';
import React from 'react';
import { cleanCiteprocAuthors } from 'lib/extract-citation';
import { JournalResponseItem } from 'types/network-responses';
import css from './citation-input-search-result-single.module.css';
import { removeTags, startCase } from 'lib/util';

interface CitationInputSearchJournalResultProps {
  item: JournalResponseItem;
}

const CitationInputSearchJournalResult: React.FC<
  CitationInputSearchJournalResultProps
> = ({ item }): JSX.Element => {
  return (
    <div key={item.DOI}>
      <div className={css.row}>
        <LabelMedium>
          {item.title &&
            (item.title[0] == '' ? 'Untitled' : removeTags(item.title[0]))}
        </LabelMedium>
      </div>
      <div className={css.row}>
        {item.author &&
          cleanCiteprocAuthors(item.author).map(
            (author: Record<string, any>, index: number) => {
              return (
                <span key={index}>
                  <LabelSmall color={colors.grey.dark}>
                    {index !== 0 && <span>, </span>}
                    {author.given} {author.family}
                  </LabelSmall>
                </span>
              );
            },
          )}
      </div>
      <div className={css.row}>
        <LabelSmall color={colors.grey.dark}>
          {item.issued['date-parts'][0][0]} &bull;{' '}
        </LabelSmall>
        <LabelSmall color={colors.grey.dark}>
          {item['container-title'] && item['container-title'][0]} &bull;
        </LabelSmall>
        <LabelSmall color={colors.grey.dark}> {item.DOI}</LabelSmall>
      </div>
      {item.type !== 'other' && (
        <div className={css.row}>
          <LabelSmall color={colors.grey.dark}>
            {startCase(item.type)}
          </LabelSmall>
        </div>
      )}
      <div className={css.row}>
        <LabelSmall color={colors.grey.dark}>
          <a
            href={item.URL}
            onClick={(ev) => ev.stopPropagation()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={css.icon}>
              <LinkSVG />
            </span>
            Show more
          </a>
        </LabelSmall>
      </div>
    </div>
  );
};

export default CitationInputSearchJournalResult;
