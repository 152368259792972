import React from 'react';
import citationForms from 'types/citation-forms';
import CitationTypes from 'types/citation-types';
import * as CitationActions from 'redux/modules/citation-module';
import * as CitationListModel from 'models/citation-list-model';
import IconButton from 'components/common/icon-button';
import { event } from 'lib/gtag';
import { useAppDispatch } from 'redux/store';
import Tracking from 'lib/tracking';
import { useStore } from 'react-redux';
import { CitationButton } from './citation-button';
import css from './citation-type-picker-list.module.css';
import CitationTypePickerSearchContainer from './citation-type-picker-search-container';

interface CitationTypePickerListProps {
  onClickShowMore: () => void;
  onClickShowLess: () => void;
  onClickCitationInputType?: () => void;
  compressed: boolean;
}

const CitationTypePickerList: React.FC<CitationTypePickerListProps> = (
  props: CitationTypePickerListProps,
): JSX.Element => {
  const dispatch = useAppDispatch();
  const store = useStore();

  let unprioritizedCitationForms = Object.keys(citationForms).filter(
    (formKey) => !citationForms[formKey as CitationTypes].isPrioritized,
  );
  if (props.compressed) {
    unprioritizedCitationForms = unprioritizedCitationForms.slice(0, 5);
  }

  const handleClickCitationInputType = (
    citationTypeKey: CitationTypes,
  ): void => {
    Tracking.trackEvent('Picked a citation type', {
      citationType: citationTypeKey,
    });
    event({ action: 'Started adding citation' });
    dispatch(CitationActions.finishEditingCitation());
    CitationListModel.saveCurrentList(store);
    if (props.onClickCitationInputType) {
      props.onClickCitationInputType();
    }

    dispatch(CitationActions.startNewCitation(citationTypeKey));
  };

  return (
    <div className={css.citationTypePickerList}>
      <div className={css.container}>
        <div className={css.citationTypePickerListContent}>
          <CitationTypePickerSearchContainer />
        </div>
        <div className={css.citationTypePickerListShowMore}>
          {props.compressed ? (
            <IconButton
              key="show-more"
              onClick={() => props.onClickShowMore()}
              label="Show more"
              icon="more"
              size="medium"
              autofill={true}
              type="brand"
            />
          ) : (
            <IconButton
              key="show-less"
              onClick={() => props.onClickShowLess()}
              label="Show less"
              icon="more"
              size="medium"
              autofill={true}
              type="brand"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CitationTypePickerList;
