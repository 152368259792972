export const hotjarCode = `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:1899898,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

const enableHotjar = (): void => {
  if (!document.querySelector('[key=hotjar]')) {
    const scriptHotjar = document.createElement('script');
    scriptHotjar.innerHTML = hotjarCode;
    scriptHotjar.setAttribute('key', 'hotjar');
    document.body.appendChild(scriptHotjar);
  }
};

export default enableHotjar;
