import React, { useRef } from 'react';
import useInputFocus from 'lib/hooks/useInputFocus';
import usePrevious from 'lib/hooks/usePrevious';
import css from './input-year.module.css';

export type DateValue = Record<string, (string | number | undefined)[][]>;

interface InputDateProps {
  citationId: string;
  name?: string;
  value: DateValue;
  onChange: (newValue: DateValue) => void;
  isRequired?: boolean;
  isPrimary?: boolean;
  isFocused?: boolean;
}

const InputYear: React.FC<InputDateProps> = (
  props: InputDateProps,
): JSX.Element => {
  const year = useRef<HTMLInputElement>(null);

  const prevCitationId = usePrevious(props.citationId);
  if (prevCitationId !== props.citationId) {
    if (year.current) year.current.value = '';
  }

  const handleChange = (): void => {
    if (
      year.current &&
      !(
        Number.isInteger(parseInt(year.current.value || '', 10)) ||
        year.current.value === '-'
      )
    ) {
      year.current.value = '';
    }
    const currentYear = year.current?.value && parseInt(year.current.value);
    const dateParts: (string | number | undefined)[] = [];
    dateParts.push(currentYear);
    props.onChange({
      'date-parts': [dateParts],
    });
  };

  useInputFocus(props.isFocused, year);

  return (
    <div>
      <div className={css.inputContainer}>
        <input
          ref={year}
          type="text"
          inputMode="numeric"
          data-hj-allow
          data-clarity-unmask="true"
          placeholder={props.isRequired ? 'Required' : ''}
          id={`${props.name}-year`}
          name={`${props.name}-year`}
          value={
            props.value &&
            props.value['date-parts'] &&
            props.value['date-parts'][0] &&
            props.value['date-parts'][0][0]
          }
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default InputYear;
