import { CitationInputType } from 'types/citation-input-type';
import React from 'react';
import Button, { ButtonSize } from 'components/common/button';
import { useAppDispatch } from 'redux/store';
import * as CitationActions from 'redux/modules/citation-module';
import Tracking from 'lib/tracking';

interface CitationInputFillManuallyButtonProps {
  value?: string;
  inputType?: CitationInputType;
  size?: ButtonSize;
}

const CitationInputFillManuallyButton = (
  props: CitationInputFillManuallyButtonProps,
): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <Button
      size={props.size || 'small'}
      type="brandDark"
      onClick={() => {
        dispatch(CitationActions.fillManually());
        Tracking.trackEvent('Clicked fill manually', {
          type: 'single result',
        });
        if (!props.value) return;
        switch (props.inputType) {
          case 'DOI':
            {
              dispatch(
                CitationActions.editCurrentCitation({
                  DOI: props.value,
                }),
              );
            }
            break;
          case 'url':
            {
              let urlWithPrefix = '';
              if (props.value.trim() !== '') {
                urlWithPrefix = !props.value.startsWith('http')
                  ? 'https://' + props.value
                  : props.value;
              }
              dispatch(
                CitationActions.editCurrentCitation({
                  URL: urlWithPrefix,
                }),
              );
            }
            break;
        }
      }}
    >
      Fill manually
    </Button>
  );
};

export default CitationInputFillManuallyButton;
