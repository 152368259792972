import React, { useState } from 'react';
import IconButton from 'components/common/icon-button';
import ImportListOptions from './import-list-options';
import css from './citation-import.module.css';

interface CitationImportProps {
  text?: string;
}

const CitationImport = (props: CitationImportProps): JSX.Element => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const handleClickButton = (): void => {
    setShowOptions(!showOptions);
  };
  return (
    <div className={css.importCitations}>
      <IconButton
        onClick={handleClickButton}
        label={props.text}
        icon="import"
        size="small"
        type="brand"
      />
      {showOptions && (
        <ImportListOptions
          showOptions={showOptions}
          setShowOptions={setShowOptions}
        />
      )}
    </div>
  );
};

export default CitationImport;
