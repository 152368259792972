import React from 'react';
import cx from 'classnames';
import IconImport from 'static/svgs/icon/20/import.svg';
import IconMore from 'static/svgs/icon/20/more.svg';
import css from './icon-button.module.css';

interface IconButtonProps {
  type?: 'dark' | 'dark-reversed' | 'brand' | 'light';
  size?: 'small' | 'medium' | 'large';
  label?: string;
  icon: string;
  autofill?: boolean;
  onClick?: (...args: any[]) => any;
}

const IconButton: React.FC<IconButtonProps> = ({
  type = 'brand',
  size = 'small',
  icon,
  autofill = false,
  label,
  onClick,
}: IconButtonProps) => {
  const classNames = cx({
    [css.iconButton]: true,
    [css.iconWithLabel]: label,
    [css.iconWithoutLabel]: !label,
    [css.iconButtonDark]: type === 'dark',
    [css.iconButtonDarkReversed]: type === 'dark-reversed',
    [css.iconButtonBrand]: type === 'brand',
    [css.iconButtonLight]: type === 'light',
    [css.iconButtonSmall]: size === 'small',
    [css.iconButtonMedium]: size === 'medium',
    [css.iconButtonLarge]: size === 'large',
    [css.iconButtonAutofill]: autofill,
  });

  const props: React.ButtonHTMLAttributes<HTMLButtonElement> = {
    onClick,
    type: 'submit',
    name: label,
  };

  const iconComponent = (iconName: string): JSX.Element | undefined => {
    switch (iconName) {
      case 'import':
        return <IconImport />;
      case 'more':
        return <IconMore />;
      default:
        break;
    }
  };

  return (
    <button {...props} className={classNames}>
      <div
        className={cx(
          css.iconButtonIcon,
          props.name
            ? css.iconButtonIconWithName
            : css.iconButtonIconWithoutName,
        )}
      >
        {iconComponent(icon)}
      </div>

      <div>{label}</div>
    </button>
  );
};
export default IconButton;
