import { LabelSmall } from 'components/common/typography';
import { colors } from 'css/variables';
import * as React from 'react';

interface BulletedSpanProps {
  items: (string | undefined)[];
}
const BulletedSpan = (props: BulletedSpanProps): JSX.Element => {
  const filteredItems = props.items.filter((item) => {
    return item !== undefined;
  });
  return (
    <span>
      {filteredItems.map((item, index) => {
        return (
          <LabelSmall key={item} color={colors.grey.dark}>
            {item}
            {index !== filteredItems.length - 1 && <span> &bull; </span>}
          </LabelSmall>
        );
      })}
    </span>
  );
};

export default BulletedSpan;
