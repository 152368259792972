import React, { useState } from 'react';
import extractCitation from 'lib/extract-citation';
import * as CitationListModel from '../../models/citation-list-model';
import CitationTypes from 'types/citation-types';
import CitationInputSearchSingle from './citation-input-search-single';
import * as CitationActions from 'redux/modules/citation-module';
import { Store, useAppDispatch } from 'redux/store';
import Tracking from 'lib/tracking';
import { mod } from 'lib/mod';
import {
  getUrlReqErrorFromResStatus,
  processInputAndUrlReqError,
} from 'lib/url-req-error-helpers';
import citationTypeToCSL from 'lib/citation-type-to-csl';
import CitationInputSearchResultSingle from './citation-input-search-result-single';
import useReqLimiter from 'lib/hooks/useReqLimiter';
import { AxiosResponse } from 'axios';
import { CitationDataLoose } from 'types/fields';
import { UrlReqError } from 'types/url-req-error';
import {
  capitalizeSubtitles,
  isTitleCase,
  toSentenceCase,
} from 'lib/string-formatting';
import { useSelector, useStore } from 'react-redux';
import autoScrollToElementWithId from 'lib/auto-scroll-to-element-with-id';
import css from './citation-input-search-website.module.css';
import useSearchWebsiteMetadataProgressTask from 'lib/hooks/useSearchWebsiteMetadataProgressTask';

interface CitationInputSearchWebsiteProps {
  citationType: CitationTypes;
  setShowInfo: (showInfo: boolean) => void;
}

const CitationInputSearchWebsite: React.FC<CitationInputSearchWebsiteProps> = (
  props: CitationInputSearchWebsiteProps,
): JSX.Element => {
  const [response, setResponse] = useState<Record<string, any>>();
  const [reqError, setReqError] = useState<UrlReqError>();
  const store = useStore();
  const currentCitationStyle = useSelector(
    (state: Store) => state.citation.citationStyle,
  );
  const nlpData = useSelector((state: Store) => state.citation.citationNlpData);

  const isAPA = currentCitationStyle.name.includes(
    'American Psychological Association',
  );
  const [value, setValue] = useState<string>('');
  const [faviconDisplay, setFaviconDisplay] = useState<'none' | 'block'>(
    'block',
  );
  const dispatch = useAppDispatch();
  const searchWebsiteMetadataProgressTask =
    useSearchWebsiteMetadataProgressTask();

  const handleChange = async (inputVal: string): Promise<void> => {
    setReqError(undefined);
    setResponse(undefined);
    setValue(inputVal.trim());
  };

  const handleClickCitation = (): void => {
    if (response) {
      const citation = extractCitation('scraper', response);
      if (citation.title && isAPA) {
        if (isTitleCase(citation.title)) {
          citation.title = toSentenceCase(
            citation.title,
            nlpData[citation.title],
          );
        } else {
          citation.title = capitalizeSubtitles(citation.title); // less aggressive than the first
        }
      }
      dispatch(CitationActions.editCurrentCitation(citation));
      const regularType =
        props.citationType === 'website' // websites are more a blank slate
          ? response.regularType
          : props.citationType;
      if (regularType) {
        const CSLType = citationTypeToCSL(regularType);
        Tracking.trackEvent('Finished editing a citation', {
          citationType: regularType,
        });
        dispatch(
          CitationActions.editCurrentCitation({ type: CSLType, regularType }),
        );
        dispatch(CitationActions.setCitationType(regularType));
      }
      if ((window.innerWidth || document.documentElement.clientWidth) > 590) {
        props.setShowInfo(true);
        dispatch(CitationActions.fillManually());
      } else {
        dispatch(CitationActions.finishEditingCitation());
      }
      autoScrollToElementWithId('last-made-citation');
      CitationListModel.saveCurrentList(store);
    }
  };

  const alwaysRunBeforeCallback = (): void => {
    setReqError(undefined);
    setResponse({ loading: true });
  };

  const reqCallback = async (
    value: string,
  ): Promise<AxiosResponse<CitationDataLoose>> => {
    return await searchWebsiteMetadataProgressTask.run(value, (progress) => {
      setResponse({ loading: { text: `Analyzing ${progress}%` } });
    });
  };

  const resCallback = (res: AxiosResponse<CitationDataLoose>): void => {
    setReqError(undefined);
    if (res) {
      setResponse(res.data);
      setFaviconDisplay('block');
    } else {
      setResponse(undefined);
    }
  };

  const onError = (e: any): void => {
    setResponse(undefined);
    if (!e.response) return;
    const reqError = getUrlReqErrorFromResStatus(e.response, e.request);
    if (reqError !== undefined) {
      setReqError(reqError);
    }
  };

  const { errorMsg, errorFillManually, invalidInput } =
    processInputAndUrlReqError(value, reqError);

  useReqLimiter(
    alwaysRunBeforeCallback,
    reqCallback,
    resCallback,
    onError,
    { delay: 200, type: 'debounce', dontCallReq: Boolean(invalidInput) },
    [value],
    value,
  );

  const renderSearchResult = (item: Record<string, any>): JSX.Element => {
    return (
      <div className={css.overallWrapper}>
        {item.favicon && (
          <span
            className={css.faviconContainer}
            style={{
              display: faviconDisplay,
            }}
          >
            <img
              className={css.favicon}
              src={item.favicon}
              alt="favicon"
              onError={() => {
                setFaviconDisplay('none');
              }}
            />
          </span>
        )}
        <span key={item.title} className={css.details}>
          <span
            style={{
              marginLeft: mod(2),
            }}
          >
            <CitationInputSearchResultSingle item={item} />
          </span>
        </span>
      </div>
    );
  };

  return (
    <div>
      <CitationInputSearchSingle
        citationType={props.citationType}
        type={CitationTypes['website']}
        error={errorMsg || ''}
        errorFillManually={errorFillManually}
        item={response}
        placeholder="Paste url"
        onClickCitation={handleClickCitation}
        onChange={handleChange}
        renderSearchResult={renderSearchResult}
        inputType="url"
      />
    </div>
  );
};

export default CitationInputSearchWebsite;
