import React, { useEffect, useState } from 'react';
import { LabelMedium, LabelSmall } from 'components/common/typography';
import { breakpointInts, colors } from 'css/variables';
import LinkSVG from 'static/svgs/icon/12/external link.svg';
import BulletedSpan from './bulleted-span';
import css from './citation-input-search-result-single.module.css';

const MAX_LINK_LEN = 400;

interface CitationInputSearchResultSingleProps {
  item: Record<string, any>; // (CitationDataLoose)
}

const CitationInputSearchResultSingle: React.FC<
  CitationInputSearchResultSingleProps
> = ({ item }): JSX.Element => {
  const [isSmallViewport, setIsSmallViewport] = useState<boolean>(
    window.innerWidth < breakpointInts.small,
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < breakpointInts.small && !isSmallViewport) {
        setIsSmallViewport(true);
      } else if (window.innerWidth >= breakpointInts.small && isSmallViewport) {
        setIsSmallViewport(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallViewport]);

  let date;
  if (item.issued && item.issued['date-parts']) {
    if (item.issued['date-parts'][0][0]) {
      date = item.issued['date-parts'][0][0];
    }
    if (item.issued['date-parts'][0][1]) {
      if (date) date += '.';
      date += item.issued['date-parts'][0][1];
    }
    if (item.issued['date-parts'][0][2]) {
      if (date) date += '.';
      date += item.issued['date-parts'][0][2];
    }
  }

  return (
    <>
      <div className={css.row}>
        <LabelMedium>{item.title}</LabelMedium>
      </div>
      <div className={css.row}>
        {item.author &&
          item.author.map((author: Record<string, any>, index: number) => {
            return (
              <span key={index}>
                <LabelSmall color={colors.grey.dark}>
                  {index !== 0 && <span>, </span>}
                  {author.given} {author.family}
                </LabelSmall>
              </span>
            );
          })}
      </div>

      <BulletedSpan items={[date, item.publisher, item['container-title']]} />
      <LabelSmall color={colors.grey.dark}>
        {' '}
        {item.DOI || item.ISBN || item.ISSN}
      </LabelSmall>

      <div className={css.row}>
        <LabelSmall color={colors.grey.dark}>
          <span>
            {item.URL.length > MAX_LINK_LEN && isSmallViewport
              ? item.URL.substring(0, MAX_LINK_LEN) + '...'
              : item.URL}
          </span>{' '}
          <a
            href={item.URL}
            onClick={(ev) => ev.stopPropagation()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={css.icon}>
              <LinkSVG />
            </span>
          </a>
        </LabelSmall>
      </div>
    </>
  );
};

export default CitationInputSearchResultSingle;
