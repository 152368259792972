import React, { useState } from 'react';
import InputText from './form-inputs/input-text';
import * as CitationActions from 'redux/modules/citation-module';
import Button from 'components/common/button';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'redux/store';
import CitationCopyButton from './citation-copy-button';
import { getCurrentCitation } from 'redux/selectors/citation-selector';
import { LabelMedium } from 'components/common/typography';
import FormLabel from './form-inputs/form-label';
import {
  CitationLocation,
  getCitationLocation,
  getInTextCitations,
} from 'lib/get-citations';
import CitationCopyPopup from './citation-copy-popup';
import screenWidthIsGdocsExtension from 'lib/screen-width-is-gdocs-extension';
import css from './citation-intext-footer-form.module.css';
import cx from 'classnames';

const CitationIntextFooterForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [locator, setLocator] = useState<string | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const citationStyle = useSelector(
    // this ensures it rerenders
    (state: Store) => state.citation.citationStyle,
  );
  const currCitation = useSelector((state: Store) => getCurrentCitation(state));
  const currCitationIndex = useSelector(
    (state: Store) => state.citation.citationInputIndex,
  );
  const citationIDs = useSelector((state: Store) =>
    state.citation.citations.map((c) => c.data.id),
  );

  const { author } = currCitation.data;

  // have to pass ALL citation IDs since in-text format depends on entire citation list (not just curr one)
  const inTextWithAuthor = getInTextCitations(citationIDs, {
    locator,
    label: 'page',
  })[currCitationIndex];

  const inTextNoAuthor = getInTextCitations(citationIDs, {
    locator,
    label: 'page',
    'suppress-author': true,
  })[currCitationIndex];

  const inTextAuthorOnly = getInTextCitations(citationIDs, {
    locator,
    label: 'page',
    'author-only': true,
  })[currCitationIndex];

  const formattedAuthorExists =
    inTextAuthorOnly !== '[NO_PRINTED_FORM]' && author && author.length > 0;

  return (
    <div className={css.citationInputForm}>
      <div className={css.citationInputFormHeaderContainer}>
        <div className={css.citationInputFormHeader}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LabelMedium style={{ marginRight: 10 }}>
              {getCitationLocation() === CitationLocation.Intext
                ? 'Create in-text citation'
                : 'Get footnote citation'}
            </LabelMedium>
          </div>
          <div className={css.citationInputFormButtonContainer}>
            <Button
              size="medium"
              onClick={async () => {
                dispatch(CitationActions.finishEditingCitation());
                if (screenWidthIsGdocsExtension()) {
                  setTimeout(
                    () =>
                      document
                        .getElementById('last-made-citation')
                        ?.scrollIntoView({ block: 'center' }),
                    0,
                  );
                }
              }}
              style={{ marginLeft: 24 }}
            >
              Done
            </Button>
          </div>
        </div>
      </div>
      <div className={cx(css.citationInputFormContent, css.inTextCitationForm)}>
        {getCitationLocation() === CitationLocation.Intext ? (
          <>
            <div className={css.primaryContainer}>
              <div className={css.pageNumberContainer}>
                <FormLabel
                  style={{
                    display: 'inline-block',
                    marginLeft: '2px',
                    marginBottom: '0.5em',
                  }}
                  htmlFor="page"
                >
                  Page number
                </FormLabel>
                <InputText
                  name="page"
                  value={locator}
                  isRequired={false}
                  isPrimary={false}
                  isFocused={true}
                  onChange={(newValue: string) => setLocator(newValue)}
                  placeholder="e.g. 20 or 20-23"
                />
              </div>
              <div className={css.inTextContainer}>
                <FormLabel
                  style={{
                    display: 'inline-block',
                    marginLeft: '2px',
                    marginBottom: '0.5em',
                  }}
                >
                  In-text citation
                </FormLabel>
                <div>
                  <span className={css.inTextContent}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: inTextWithAuthor,
                      }}
                    />
                  </span>
                  <CitationCopyButton
                    height="14"
                    width="14"
                    selected={true} // true so bg color is light
                    htmlToCopy={inTextWithAuthor}
                    intext={true}
                  />
                </div>
              </div>
            </div>
            <div className={css.examplesContainer}>
              <strong className={css.examplesHeader}>
                In-text citations can be placed inside a sentence or enclosed in
                round brackets.
              </strong>
              {formattedAuthorExists && (
                <div>
                  <span>
                    <span className={css.inTextCitation}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: inTextAuthorOnly,
                        }}
                      />
                    </span>{' '}
                    claim{author && author.length > 1 ? '' : 's'} that...{' '}
                  </span>
                  {inTextNoAuthor !== '[NO_PRINTED_FORM]' && (
                    <>
                      <span
                        className={cx(
                          css.inTextCitation,
                          css.exampleInTextCitation,
                        )}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: inTextNoAuthor,
                          }}
                        />
                      </span>
                      <CitationCopyButton
                        height="14"
                        width="14"
                        selected={false} // false so bg color is dark
                        htmlToCopy={inTextNoAuthor}
                        intext={true}
                      />
                    </>
                  )}
                </div>
              )}
              <div>
                <span>Latest studies suggest that... </span>
                <span
                  className={cx(css.inTextCitation, css.exampleInTextCitation)}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: inTextWithAuthor,
                    }}
                  />
                </span>
                <CitationCopyButton
                  height="14"
                  width="14"
                  selected={false} // false so bg color is dark
                  htmlToCopy={inTextWithAuthor}
                  intext={true}
                />
              </div>
              <hr className={css.examplesContainerLine} />
              <div className={css.parenthesesExampleContainer}>
                <div>
                  Using the <strong>author&apos;s name in parentheses</strong>{' '}
                  makes it perfectly clear that you are paraphrasing
                  someone&apos;s work:
                </div>
                <div>
                  When one decides to play the game of thrones, one must accept
                  they can either win or die{' '}
                  <span className={css.inTextCitation}>(Martin 43)</span>.
                </div>
              </div>
              <div>
                Using the{' '}
                <strong>author&apos;s name as a part of a narrative</strong>{' '}
                helps preserve the flow of the sentence:
              </div>
              <div>
                <span className={css.inTextCitation}>Fitzgerald</span> urged his
                readers not to criticize others but, instead, reflect on their
                own privilege <span className={css.inTextCitation}>(254)</span>.
              </div>
            </div>
          </>
        ) : (
          <div className={css.footnoteContainer}>
            <CitationCopyPopup
              citationString={inTextWithAuthor}
              intext={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CitationIntextFooterForm;
