import cx from 'classnames';
import { colors } from 'css/variables';
import copyCitToClipboard from 'lib/copy-citation';
import React, { useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { Store } from 'redux/store';
import css from './citation-copy-button.module.css';

const CopyIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
): JSX.Element => (
  <svg
    strokeLinejoin="round"
    strokeLinecap="round"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    viewBox="0 0 24 24"
    height={24}
    width={24}
    className="icon"
    {...props}
  >
    <path fill="none" d="M0 0h24v24H0z" stroke="none" />
    <rect rx={2} height={12} width={12} y={8} x={8} />
    <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
  </svg>
);

// change svg stroke to #006400 if green desired colour
const CopiedCheck: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="black"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M5 12l5 5l10 -10" />
  </svg>
);

interface CitationCopyProps {
  selected: boolean;
  entryspacing?: number;
  hangingindent?: boolean;
  linespacing?: 1 | 2;
  htmlToCopy: string;
  intext: boolean;
  height?: string;
  width?: string;
}

const CitationCopy: React.FC<CitationCopyProps> = (
  props: CitationCopyProps,
): JSX.Element => {
  const [recentlyCopied, setRecentlyCopied] = useState(false);
  const store = useStore();
  const gdocsExport = useSelector((store: Store) => store.auth.gdocsExport);

  const handleClick = (): void => {
    if (recentlyCopied) {
      return;
    }
    copyCitToClipboard({
      citationString: props.htmlToCopy,
      hangingindent: props.hangingindent,
      gdocsExport,
      intext: props.intext,
      linespacing: props.linespacing,
      entryspacing: props.entryspacing,
      store,
    });
    setRecentlyCopied(true);
    setTimeout(() => {
      setRecentlyCopied(false);
    }, 2000);
  };

  const backgroundHoverColor = props.selected
    ? colors.white
    : colors.grey.light;
  return (
    <>
      <button
        className={cx(
          'copy-icon',
          css.copyIcon,
          recentlyCopied && css.recentlyCopied,
        )}
        style={
          {
            '--background-hover-color': backgroundHoverColor,
          } as React.CSSProperties
        }
        onMouseDown={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
          e.stopPropagation();
        }}
        onMouseUp={() => {
          handleClick();
        }}
        onKeyPress={(e: React.KeyboardEvent<HTMLButtonElement>) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            handleClick();
          }
        }}
      >
        <span className={css.citationButton}>
          {!recentlyCopied && (
            <CopyIcon height={props.height} width={props.width} />
          )}
          {recentlyCopied && (
            <CopiedCheck height={props.height} width={props.width} />
          )}
        </span>
        <span
          className={cx(
            'helper-text',
            css.helperText,
            recentlyCopied && css.invisible,
          )}
        >
          copy
        </span>
      </button>
    </>
  );
};

export default CitationCopy;
