import React from 'react';
import Input from 'components/common/input';
import SearchIcon from 'static/svgs/icon/17/search.svg';
import css from './citation-type-picker-search.module.css';

interface CitationTypePickerSearchProps {
  searchTerm: string;
  onClose: () => void;
  onChangeSearchTerm: (searchTerm: string) => void;
}

const CitationTypePickerSearch: React.FC<CitationTypePickerSearchProps> = (
  props: CitationTypePickerSearchProps,
) => {
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    props.onChangeSearchTerm(ev.target.value);
  };
  const handleInputKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Escape') {
      props.onClose();
    }
  };

  return (
    <div className={css.citationTypePickerSearch}>
      <Input
        onKeyDown={handleInputKeyDown}
        icon={<SearchIcon />}
        autoFocus
        onChange={handleChange}
        placeholder="Search citation types"
        value={props.searchTerm}
        style={{
          height: 45,
        }}
        data-hj-allow
      />
    </div>
  );
};

export default CitationTypePickerSearch;
