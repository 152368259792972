import { AxiosResponse } from 'axios';
import usePrevious from 'lib/hooks/usePrevious';
import useReqLimiter from 'lib/hooks/useReqLimiter';
import {
  getUrlReqErrorFromResStatus,
  processInputAndUrlReqError as validateInputAndUrlReqError,
} from 'lib/url-req-error-helpers';
import React, { useEffect, useState } from 'react';
import CitationTypes from 'types/citation-types';
import { CitationDataLoose } from 'types/fields';
import JournalSearchSubtypeProps from 'types/journal-search-subtype-props';
import { UrlReqError } from 'types/url-req-error';
import CitationInputSearchResultSingle from './citation-input-search-result-single';
import CitationInputSearchSingle from './citation-input-search-single';
import useSearchWebsiteMetadataProgressTask from 'lib/hooks/useSearchWebsiteMetadataProgressTask';

interface CitationInputSearchJournalQueryUrlProps
  extends JournalSearchSubtypeProps {
  errorMessage?: string;
  loadingMessage?: string;
  hideUrl?: boolean;
  skipUrlValidation?: boolean;
}

const CitationInputSearchJournalQueryUrl: React.FC<
  CitationInputSearchJournalQueryUrlProps
> = (props): JSX.Element => {
  const { inputVal, hideUrl = false, skipUrlValidation = false } = props;
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<number | undefined>();
  const [citationData, setCitationData] = useState<CitationDataLoose>();
  const [reqError, setReqError] = useState<UrlReqError>();
  const searchWebsiteMetadataProgressTask =
    useSearchWebsiteMetadataProgressTask();
  const prevInputVal = usePrevious(inputVal);
  const inputValHasChanged = inputVal !== prevInputVal;

  useEffect(() => {
    if (inputValHasChanged && hideUrl) {
      setReqError(UrlReqError.NoError);
      setCitationData(undefined);
      setLoading(true);
    }
  }, [inputValHasChanged, hideUrl, setReqError]);

  const onChange = (value: string): void => {
    props.handleChange(value);
    setReqError(UrlReqError.NoError);
  };

  const alwaysRunBefore = (): void => {
    setReqError(UrlReqError.NoError);
    setProgress(undefined);
    setLoading(true);
  };

  const reqCallback = async (
    url: string,
  ): Promise<AxiosResponse<CitationDataLoose>> => {
    return searchWebsiteMetadataProgressTask.run(url, setProgress);
  };

  const resCallback = (res: AxiosResponse<CitationDataLoose>): void => {
    if (res) {
      setReqError(UrlReqError.NoError);
      if (res.data) {
        setCitationData(res.data);
      }
      setProgress(undefined);
      setLoading(false);
    }
  };
  const onError = async (e: any): Promise<void> => {
    const reqError = getUrlReqErrorFromResStatus(e.response, e.request);
    if (reqError !== undefined) {
      setReqError(reqError);
    }
    setProgress(undefined);
    setLoading(false);
  };

  const { errorMsg, errorFillManually, invalidInput } =
    validateInputAndUrlReqError(inputVal, reqError, skipUrlValidation);

  useReqLimiter(
    alwaysRunBefore,
    reqCallback,
    resCallback,
    onError,
    {
      delay: 200,
      type: 'debounce',
      dontCallReq:
        invalidInput ||
        !inputValHasChanged ||
        Boolean(props.loadingMessage) ||
        Boolean(props.errorMessage),
    },
    [
      inputVal,
      invalidInput,
      inputValHasChanged,
      props.loadingMessage,
      props.errorMessage,
    ],
    inputVal,
  );

  const loadingText =
    props.loadingMessage ||
    (loading &&
      `Analyzing${
        typeof progress !== 'number' ? '' : ` ${progress.toString() + '%'}`
      }`);

  const resItem = loadingText
    ? {
        loading: {
          text: loadingText,
        },
      }
    : citationData;

  return (
    <CitationInputSearchSingle
      citationType={props.citationType}
      type={CitationTypes.journalArticle}
      item={resItem}
      onClickCitation={() => props.handleClickCitation(citationData)}
      onChange={onChange}
      renderSearchResult={(item: Record<string, any>) => (
        <CitationInputSearchResultSingle item={item} />
      )}
      error={props.errorMessage || errorMsg}
      errorFillManually={errorFillManually}
      placeholder={props.placeholder}
      existingValue={hideUrl ? '' : props.inputVal}
      inputType="url"
      hideInvalidUrl={hideUrl}
      uploadButton={props.uploadButton}
      inputDisabled={Boolean(props.loadingMessage)}
    />
  );
};

export default CitationInputSearchJournalQueryUrl;
