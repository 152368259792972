import cx from 'classnames';
import Button from 'components/common/button';
import SelectedSVG from 'components/common/selected-svg';
import copyCitToClipboard from 'lib/copy-citation';
import {
  CitationLocation,
  getCitationLocation,
  isCurrentStyleSortingBibliography,
} from 'lib/get-citations';
import { reportGeneralException } from 'lib/sentry';
import Tracking from 'lib/tracking';
import { useEffect, useState } from 'react';
import { ConnectDragSource, useDragDropManager } from 'react-dnd';
import { useSelector, useStore } from 'react-redux';
import * as CitationActions from 'redux/modules/citation-module';
import { EditStates } from 'redux/modules/citation-module';
import {
  getCurrentList,
  isAllowedToEdit,
} from 'redux/selectors/citation-list-selector';
import { Store, useAppDispatch } from 'redux/store';
import DragCitationIcon from 'static/svgs/icon/drag-citation.svg';
import CitationCopyPopup, { CopiedCheck } from './citation-copy-popup';
import CitationDeleteIcon from './citation-delete-icon';
import CitationOutputCitationFieldWarnings from './citation-output-citation-field-warnings';
import css from './citation-output-citation.module.css';

export interface CitationOutputCitationProps {
  hiddenIds: string[];
  editState: EditStates;
  entryspacing: number;
  hangingindent: boolean;
  linespacing: 1 | 2;
  isManual: boolean;
  index: number;
  citation: CitationActions.Citation;
  selected: boolean;
  citationString: string;
  dragRef?: ConnectDragSource;
  citationsDeleting: string[];
}

const CitationOutputCitation = (
  props: CitationOutputCitationProps,
): JSX.Element | null => {
  const dndManager = useDragDropManager();
  const [recentlyCopied, setRecentlyCopied] = useState(false);
  const store = useStore();
  const glowingCitation = useSelector(
    (state: Store) => state.citation.glowingCitation,
  );
  const style = useSelector((state: Store) => state.citation.citationStyle);
  const dispatch = useAppDispatch();
  const [isDragging, setIsDragging] = useState(false);
  const [bibliographyAutomaticallySorted, setBibliographyAutomaticallySorted] =
    useState(false);

  useEffect(() => {
    const monitor = dndManager.getMonitor();
    monitor.subscribeToStateChange(() => {
      setIsDragging(monitor.isDragging());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBibliographyAutomaticallySorted(isCurrentStyleSortingBibliography());
  }, [style.name]);

  const citationId = props.citation?.data?.id;
  const isDeleting = props.citationsDeleting?.includes(citationId);
  const isCitationEmpty = (props.hiddenIds || []).includes(citationId);
  const extraProps =
    props.selected && !isCitationEmpty ? { id: 'selected-citation' } : {};
  const lastCitationExtraProps =
    glowingCitation.toString() === citationId
      ? { id: 'last-made-citation' }
      : {};
  const email = useSelector((state: Store) => state.auth.data?.email);
  const currentList = useSelector(getCurrentList);
  const allowedToEdit = isAllowedToEdit(currentList, email);
  const gdocsExport = useSelector((store: Store) => store.auth.gdocsExport);

  // Sometimes hiddenIds are undefined and we do not understand why.
  // This causes errors being sent to sentry, because this component
  // and its children depend on this values being defined.
  // We put a trap on that situation to understand what's going on.
  useEffect(() => {
    if (!props.hiddenIds) {
      reportGeneralException('Unexpected undefined props', { store, props });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hiddenIds]);
  // Stop rendering to avoid all consequences down the component tree.
  if (!props.hiddenIds) {
    return null;
  }

  const handleCopy = (): void => {
    if (recentlyCopied) {
      return;
    }
    copyCitToClipboard({
      citationString: props.citationString,
      hangingindent: props.hangingindent,
      gdocsExport,
      linespacing: props.linespacing,
      entryspacing: props.entryspacing,
      intext: false,
      store,
      trackingMessage: 'single button clipboard',
    });
    setRecentlyCopied(true);
    setTimeout(() => {
      setRecentlyCopied(false);
    }, 2000);
  };

  return (
    <div
      className={css.citation}
      key={citationId}
      style={{ opacity: isDeleting ? 0.5 : 1 }}
    >
      <div
        {...extraProps}
        {...lastCitationExtraProps}
        className={cx(
          css.citationMain,
          'paragraph-medium',
          isCitationEmpty && css.citationMainEmpty,
          props.selected && css.selected,
          !props.selected &&
            glowingCitation.toString() === citationId &&
            css.glowing,
        )}
        tabIndex={0}
        role="button"
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            dispatch(CitationActions.selectCitation(citationId));
          }
        }}
      >
        {allowedToEdit && (
          <div style={{ position: 'absolute', right: -18, top: -18 }}>
            <CitationDeleteIcon
              citationToDelete={props.citation}
              height="14"
              width="14"
              isEmpty={isCitationEmpty}
            />
          </div>
        )}
        {isCitationEmpty && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
              margin: '0 5px',
            }}
          >
            <span>empty citation</span>
          </div>
        )}
        {!isCitationEmpty && (
          <>
            <div className={css.citationTextContainer}>
              {!bibliographyAutomaticallySorted && (
                <div className={css.dragIcon} ref={props.dragRef}>
                  <DragCitationIcon />
                </div>
              )}
              <CitationCopyPopup
                entryspacing={props.entryspacing}
                hangingindent={props.hangingindent}
                linespacing={props.linespacing}
                citationString={props.citationString}
                intext={false}
              />
            </div>
            {allowedToEdit && (
              <CitationOutputCitationFieldWarnings citation={props.citation} />
            )}
          </>
        )}
        {!isCitationEmpty && !isDragging && (
          <div className={css.citationFooter}>
            {recentlyCopied ? (
              <Button
                type="default"
                size="small"
                fullWidth
                style={{ margin: '0 5px', cursor: 'not-allowed' }}
                onClick={handleCopy}
              >
                <span>
                  <CopiedCheck /> Copied
                </span>
              </Button>
            ) : (
              <Button
                type="default"
                size="small"
                fullWidth
                style={{ margin: '0 5px' }}
                onClick={handleCopy}
              >
                Copy
              </Button>
            )}
            {allowedToEdit && (
              <Button
                type="default"
                size="small"
                fullWidth
                style={{ margin: '0 5px' }}
                onClick={(ev) => {
                  ev.preventDefault();
                  dispatch(CitationActions.selectCitation(citationId));
                  dispatch(CitationActions.triggerInputFormGlow());
                }}
              >
                Edit
              </Button>
            )}
            <Button
              type="default"
              size="small"
              fullWidth
              style={{ margin: '0 5px' }}
              onClick={(): void => {
                dispatch(CitationActions.selectIntextFooter(citationId));
                dispatch(CitationActions.triggerInputFormGlow());
                Tracking.trackEvent(
                  `Clicked ${
                    getCitationLocation() === CitationLocation.Intext
                      ? 'In-text citation button'
                      : 'Footnote button'
                  }`,
                );
              }}
            >
              {getCitationLocation() === CitationLocation.Intext ? (
                <div>In-text</div>
              ) : (
                'Footnote'
              )}
            </Button>
          </div>
        )}
        {props.selected &&
          (props.editState !== EditStates.NONE || props.isManual) && (
            <SelectedSVG />
          )}
      </div>
    </div>
  );
};

export default CitationOutputCitation;
