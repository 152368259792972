import React, { useEffect, useRef } from 'react';
import * as CitationActions from 'redux/modules/citation-module';
import * as CitationListModel from '../../models/citation-list-model';
import { useDrag, useDrop, DropTargetMonitor, XYCoord } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useAppDispatch } from 'redux/store';
import { useStore } from 'react-redux';
import CitationOutputCitation, {
  CitationOutputCitationProps,
} from './citation-output-citation';

const DND_TYPE = 'Citation';

const DraggableCitationOutput = (
  props: CitationOutputCitationProps,
): JSX.Element => {
  const dispatch = useAppDispatch();
  const store = useStore();
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, dragRef, dragPreview] = useDrag({
    type: DND_TYPE,
    item: () => props,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dragPreview]);

  const [{ handlerId }, dropRef] = useDrop({
    accept: DND_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: CitationOutputCitationProps, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = props.index;

      monitor.getDifferenceFromInitialOffset();

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      // Update citations state
      dispatch(
        CitationActions.moveCitation({
          fromIndex: dragIndex,
          toIndex: hoverIndex,
        }),
      );

      // Update item index
      item.index = hoverIndex;
    },
    drop() {
      CitationListModel.saveCurrentList(store);
    },
  });

  dropRef(ref);

  return (
    <div
      ref={ref}
      className="citation"
      key={props.citation.data.id}
      data-handler-id={handlerId}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <CitationOutputCitation {...props} dragRef={dragRef} />
    </div>
  );
};

export default DraggableCitationOutput;
