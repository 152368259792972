import React from 'react';
import css from './input-text-area.module.css';

interface InputTextAreaProps {
  name?: string;
  value: string;
  onChange: (newValue: string) => void;
  isRequired?: boolean;
  isTextArea?: boolean;
}

const InputTextArea: React.FC<InputTextAreaProps> = (
  props: InputTextAreaProps,
): JSX.Element => {
  return (
    <div className={css.citationInputTextArea}>
      <textarea
        name={props.name}
        data-hj-allow
        data-clarity-unmask="true"
        className={css.textarea}
        placeholder={props.isRequired ? 'Required' : ''}
        value={props.value}
        onChange={(ev) => props.onChange(ev.target.value)}
      />
    </div>
  );
};

export default InputTextArea;
